<div class="modal-header">
    <div class="modal-title">{{ data?.message }}</div>
    <div class="modal-close" (click)="onDismissModal()">
        <span class="icon icon-times"></span>
    </div>
</div>
<div class="modal-body" #modalBody>
    <ng-container *ngIf="data.buttons && data.buttons.length > 0; else modalEmpty">
        <div class="action-item" *ngFor="let item of data.buttons" (click)="onAction(item)">
            <div *ngIf="item.icon" class="icon {{item.icon}}"></div>{{ item.label }}
        </div>
    </ng-container>
    <ng-template #modalEmpty>
        <div class="actions-modal-empty">{{ data.emptyMessage }}</div>
    </ng-template>
</div>

