import { DeviceTypes } from '@kuki/global/shared/types/device';
import { WebKeyMap } from '@kuki/platforms/web/keymap';

export const hal = {
    appMode: 'WEB',
    deviceType: DeviceTypes.WEB,
    platform: 'WEB',
    animationType: 'angular-anim',
    keymap: WebKeyMap,
    videoModeDefault: null,
    afrTimeout: null,
    wsLogging: false,
    mediaPlayer: {
        mediaPlayerHal: 'SHAKA',
        playbackUrlType: '1es',
        playbackUrlFmt: 'mp4',
        playbackUrlBaseType: 'baseUrl',
        dolbyDigital: false,
        fpsInt: false,
        initOnStartup: false,
        chromecastReceiverAppId: '023E384E',
        airplay: true,
        epd: false
    },
    volume: null,
    cap: {
        connectionCheck: true,
        connectionCheckWifi: false,
        upgrader: false,
        universalLinks: false,
        watchDog: false,
        watchDogUrl: null,
        hdmioff: false,
        tvoff: false,
        videoOutputSettings: false,
        audioPassthrough: false,
        afr: false,
        mcast: false,
        rebootOnConnectionLost: false,
        powerupOnConnectionLost: false,
        ga: true,
        testTimeOnStartup: true,
        wakeOnRemote: false,
        sleepOnRemote: false,
        teleport: true,
        volumeControl: false
    }
};

