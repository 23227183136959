<ng-container *ngIf="!unsupported; else unsupportedContent">
    <div *ngIf="isReady()" class="app-wrapper">
        <app-header *appVisible="'header'"></app-header>
        <div class="main">
            <router-outlet></router-outlet>
        </div>
        <app-footer *appVisible="'footer'"></app-footer>
        <app-channel-switcher></app-channel-switcher>
    </div>
</ng-container>

<ng-template #unsupportedContent>
    <div class="unsupported-browser">
        <div class="notification notification-alert">
            <div class="notification-icon notification-icon-alert"></div>
            <div class="notification-content">
                <h2 [innerHTML]="'WEB.BROWSER.UNSUPPORTED_BROWSER' | translate"></h2>
                <ng-container *ngIf="unsupported === 'ios'">
                    <p [innerHTML]="'WEB.BROWSER.IOS_APP_LINK' | translate"></p>
                </ng-container>
                <ng-container *ngIf="unsupported === 'android'">
                    <p [innerHTML]="'WEB.BROWSER.ANDROID_APP_LINK' | translate"></p>
                </ng-container>
                <ng-container *ngIf="unsupported === 'ie'">
                    <p [innerHTML]="'WEB.BROWSER.SUPPORTED_BROWSERS' | translate"></p>
                </ng-container>
            </div>
        </div>
    </div>
</ng-template>

<app-notifications></app-notifications>
