import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { ModalsService } from '@kuki/web/modals/modals.service';
import { DirectivesModule } from '@kuki/global/shared/directives/directives.module';
import { PasswordModalComponent } from '@kuki/web/modals/password-modal/password-modal.component';
import { ConfirmModalComponent } from '@kuki/web/modals/confirm-modal/confirm-modal.component';
import { TranslateModule } from '@ngx-translate/core';
import { ActionsModalComponent } from '@kuki/web/modals/actions-modal/actions-modal.component';
import { ButtonsPanelModule } from '@kuki/global/features/buttons-panel/buttons-panel.module';
import { ActionsModalV2Component } from '@kuki/web/modals/actions-modal-v2/actions-modal-v2.component';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        DirectivesModule,
        TranslateModule,
        ButtonsPanelModule
    ],
    declarations: [
        PasswordModalComponent,
        ConfirmModalComponent,
        ActionsModalComponent,
        ActionsModalV2Component
    ],
    providers: [
        { provide: 'ModalsService', useClass: ModalsService },
    ]
})
export class ModalsModule {
}
