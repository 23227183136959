import { Component, ElementRef, HostListener, Inject, OnDestroy, OnInit } from '@angular/core';
import { ProfileService } from '@kuki/global/shared/services/profile.service';
import { ProfileTile } from '@kuki/global/shared/types/tile';
import { ModalsInterface } from '@kuki/global/modals/modals.interface';
import { NotificationService } from '@kuki/global/shared/modules/notification/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { SOM, SubscriptionObject } from '@kuki/global/shared/others/subscription/subscription-object';
import { from, merge } from 'rxjs';
import { startWith, switchMap } from 'rxjs/operators';
import { NavigationService } from '@kuki/global/shared/services/navigation.service';
import { hal } from '@kuki/root/platforms/hal';
import { Actions, ImageTypes, TileTypes } from '@kuki/global/shared/types/enum';
import { CoreService } from '@kuki/global/shared/services/core.service';
import { Errors } from '@kuki/global/shared/types/enum/errors';

@Component({
    selector: 'app-profile-picker',
    templateUrl: './profile-picker.component.html'
})
export class ProfilePickerComponent implements OnInit, OnDestroy {

    public ImageTypes = ImageTypes;
    public profiles: Array<ProfileTile>;
    public profilePickerVisible: boolean = false;
    private subscription: SubscriptionObject = {};

    @HostListener('document:click', [ '$event.target' ])
    public onClick(targetElement) {
        const clickedInside = this.elementRef.nativeElement.contains(targetElement);
        if (!clickedInside) {
            this.profilePickerVisible = false;
        }
    }

    constructor(
        private profileService: ProfileService,
        private navigationService: NavigationService,
        private elementRef: ElementRef,
        @Inject('ModalsService') private modalsService: ModalsInterface,
        private notificationService: NotificationService,
        private coreService: CoreService,
        private translateService: TranslateService) {
    }

    ngOnInit() {
        this.subscription.onProfilesFetched =
            merge(
                this.profileService.onProfilesFetched$.pipe(startWith(true)),
                this.profileService.onProfileChanged$
            ).subscribe(() => {
                this.profiles = [ ...this.profileService.getProfiles(this.profileService.getActiveProfile()?.profileType === 'kid') ];
            });
    }

    public toggleProfilePicker() {
        if (this.coreService.isMobilePlatform()) {
            this.navigationService.navigateOnAction(Actions.SHOW_SETTINGS);
        } else {
            if (this.profiles?.length > 0) {
                this.profilePickerVisible = !this.profilePickerVisible;
            } else {
                this.onLogout();
            }
        }
    }

    public getProfilePickerClass(profile: ProfileTile) {
        if (this.isActiveProfile(profile)) {
            return 'profile-picker-item-active';
        }
    }

    public onSelectProfile(tile: ProfileTile) {
        const activeProfile = this.profileService.getActiveProfile();
        if (activeProfile && activeProfile.id === tile.id) {
            return;
        }
        const promise = !tile.pin ? this.modalsService.openConfirmModal({
            message: this.translateService.instant('GENERAL.SWITCH_PROFILE_CONFIRM_MESSAGE')
        }) : Promise.resolve(true);
        return promise.then(() => this.profileService.activateProfileProcess(tile.id).toPromise())
            .then(() => {
                this.notificationService.show(
                    this.translateService.instant('NOTIFICATIONS.PROFILE.PROFILE_ACTIVATED',
                        { name: tile.label }));
                this.navigationService.navigateHome();
            }).catch((error) => {
                if (error.message === Errors.MODAL_CLOSE) {
                    return;
                }
                throw error;
            });
    }

    public isActiveProfile(profile: ProfileTile) {
        const activeProfile = this.profileService.getActiveProfile();
        return activeProfile?.id === profile.id;
    }

    public getActiveProfile() {
        return this.profileService.getActiveProfile();
    }

    public onLogout() {
        if (!this.subscription.logout) {
            this.subscription.logout = from(this.modalsService.openConfirmModal({ message: this.translateService.instant('GENERAL.LOGOUT_APP_CONFIRM_MESSAGE') }))
                .pipe(
                    switchMap(() => {
                        return this.coreService.logout();
                    })
                )
                .subscribe(() => {
                    this.navigationService.navigateWizard();
                }, (error) => {
                    this.subscription.logout = undefined;
                    if (error.message === Errors.MODAL_CLOSE) {
                        return;
                    }
                    throw error;
                });
        }
    }

    public onToggleProfile(profile: ProfileTile, e: Event) {
        e.stopPropagation();
        if (this.isActiveProfile(profile)) {
            this.subscription.deactiveProfile = this.profileService.deactivateProfileProcess().subscribe(() => {
                this.navigationService.navigateHome();
            }, (error) => {
                if (error.message === Errors.MODAL_CLOSE) {
                    return;
                }
                throw error;
            });
        } else {
            this.onSelectProfile(profile);
        }
    }

    public onEditProfile(profile: ProfileTile, e: Event) {
        e.stopPropagation();
        this.navigationService.navigateUpdateProfileWebMobile(profile.id);
    }

    public onDeleteProfile(profile: ProfileTile, e: Event) {
        e.stopPropagation();
        this.subscription.deleteProfile = this.profileService.deleteProfileProcesss(profile.id).subscribe({
            error: (error) => {
                if (error.message === Errors.MODAL_CLOSE) {
                    return;
                }
                throw error;
            }
        });
    }

    public isProfileTile(profile: ProfileTile) {
        return profile.type === TileTypes.PROFILE;
    }

    ngOnDestroy() {
        SOM.clearSubscriptionsObject(this.subscription);
    }
}
