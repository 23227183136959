import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable, NgZone } from '@angular/core';
import { environment } from '@kuki/environments/environment';
import { MediaPlayerHalInterface } from '@kuki/global/features/media-player/media-player-hals/media-player-hal.interface';
import { AuthService } from '@kuki/global/sections/auth/auth.service';
import { MediaService } from '@kuki/global/shared/modules/media/media.service';
import { NotificationService } from '@kuki/global/shared/modules/notification/notification.service';
import { SOM, SubscriptionObject } from '@kuki/global/shared/others/subscription/subscription-object';
import { BroadcastGapsV3Service } from '@kuki/global/shared/services/broadcast-gaps.v3.service';
import { ChannelService } from '@kuki/global/shared/services/channel.service';
import { ComponentRegisterService } from '@kuki/global/shared/services/component-register.service';
import { CoreService } from '@kuki/global/shared/services/core.service';
import { GeneralService } from '@kuki/global/shared/services/general.service';
import { LoggingService } from '@kuki/global/shared/services/logging.service';
import { ParentalControlService } from '@kuki/global/shared/services/parental-control.service';
import { PortalSettingsService } from '@kuki/global/shared/services/portal-settings.service';
import { PreferredChannelTrackService } from '@kuki/global/shared/services/preferred-channel-track.service';
import { ProfileService } from '@kuki/global/shared/services/profile.service';
import { RestrictionService } from '@kuki/global/shared/services/restriction.service';
import { SettingsService } from '@kuki/global/shared/services/settings.service';
import { TileService } from '@kuki/global/shared/services/tile.service';
import { WatchedService } from '@kuki/global/shared/services/watched.service';
import { DeviceTypes } from '@kuki/global/shared/types/device';
import { LogEvents, MediaTypes } from '@kuki/global/shared/types/enum';
import {
    AudioTrack,
    BroadcastGapGroup,
    Cert,
    Channel,
    EpgEntityAtTime,
    Interval,
    LogOptions,
    PortalSettings
} from '@kuki/global/shared/types/general';
import { Playback } from '@kuki/global/shared/types/general/playback';
import {
    MediaPlayerConfig,
    MediaPlayerError,
    MediaPlayerErrors,
    MediaPlayerParams,
    MediaPlayerTypes
} from '@kuki/global/shared/types/media-player';
import { MediaPlayerRestrictions } from '@kuki/global/shared/types/media-player/media-player-restrictions';
import { SettingsParsed } from '@kuki/global/shared/types/settings';
import { EpisodeTile, MediaTile, VodTile } from '@kuki/global/shared/types/tile';
import { hal } from '@kuki/platforms/hal';
import { PlatformHal } from '@kuki/platforms/platform-hal';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, ConnectableObservable, from, Observable, of, Subject, Subscription, throwError, timer } from 'rxjs';
import { catchError, debounceTime, filter, map, mergeMap, publishReplay, refCount, retryWhen, switchMap, take, tap } from 'rxjs/operators';

@Injectable()
export class MediaPlayerV2Service {

    private readonly TIMER_INTERVAL = 1000;
    private readonly PROTECTED_TIME = 30 * 1000;
    private readonly PUBLISH_WATCHING_INTERVAL = 5 * 60 * 1000;
    private readonly TS_PUBLISH_DELAY_LIMIT = 5 * 60 * 1000;
    private readonly PLAY_RETRY_ATTEMPTS = environment.production ? 2 : 20;
    private readonly EPG_LIVE_FETCHING_INTERVAL = 5 * 60 * 1000;
    private readonly PROTECTION_SKIPPABLE_CACHE_LIMIT = 60 * 60 * 1000;

    // general
    private mediaPlayerType: MediaPlayerTypes;
    private mediaPlayerParams: MediaPlayerParams;
    private mediaPlayerConfig: MediaPlayerConfig;
    private signIdent: string;
    private channel: Channel;
    private settings: SettingsParsed;
    private portalSettings: PortalSettings;
    private cert: Cert;
    private refreshCounter: number = 0;
    private lastLogPrevented: boolean;

    // tiles
    private tiles: EpgEntityAtTime;
    private mediaTile: MediaTile;
    private cursorTile: MediaTile;
    private watchingTile: MediaTile;
    private epgLive: { [ key: string ]: MediaTile };

    // times
    private liveTime: number;
    private watchingTime: number;
    private cursorTime: number;
    private timersStuck: { cursor?: boolean, watched?: boolean } = {};

    // states
    private pausedTime: number;
    private stopped: boolean;
    private playerLoaded: boolean;
    private seeking: boolean;
    private sleeping: boolean;
    private lockedReason: MediaPlayerRestrictions;

    // broadcast gaps
    private broadcastGapGroupActive: BroadcastGapGroup;

    // subjects
    public onPlayerInitialized: Subject<void> = new Subject<void>();
    private onPlayStarted: Subject<void> = new Subject<void>();
    private onTileChanged: Subject<void> = new Subject<void>();
    private onPlayerTimerRefresh: Subject<void> = new Subject<void>();
    private onSeeking: Subject<{ startTime: number, direction: number }> = new Subject<{ startTime: number, direction: number }>();
    private onChangeUrl: Subject<MediaPlayerParams> = new Subject<MediaPlayerParams>();
    private onPause: Subject<void> = new Subject<void>();
    private onUnPause: Subject<void> = new Subject<void>();
    private onStop: Subject<void> = new Subject<void>();
    private onError: Subject<any> = new Subject<any>();
    private onLockUpdated: Subject<void> = new Subject<void>();
    private onBroadcastGapUpdated: Subject<void> = new Subject<void>();
    private onBroadcastGapChanged: Subject<void> = new Subject<void>();

    // observables
    public playerTimer$: ConnectableObservable<number>;
    public seeking$: Observable<any> = this.onSeeking.asObservable();
    public tracksUpdated$: Observable<boolean> = this.mediaPlayerHalService.tracksUpdated$.asObservable();
    public trackActivated$: Observable<void> = this.mediaPlayerHalService.trackActivated$.asObservable();
    public buffering$: Observable<boolean> = this.mediaPlayerHalService.buffering$.asObservable();
    public onPlayerInitialized$: Observable<void> = this.onPlayStarted.asObservable();
    public onPlayStarted$: Observable<void> = this.onPlayStarted.asObservable();
    public onTileChanged$: Observable<void> = this.onTileChanged.asObservable();
    public onChangeUrl$: Observable<MediaPlayerParams> = this.onChangeUrl.asObservable();
    public onPause$: Observable<void> = this.onPause.asObservable();
    public onUnPause$: Observable<void> = this.onUnPause.asObservable();
    public onStop$: Observable<void> = this.onStop.asObservable();
    public onLockUpdated$: Observable<void> = this.onLockUpdated.asObservable();
    public onError$: Observable<any> = this.onError.asObservable();
    public onToggleFullscreen$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public onBroadcastGapChanged$: Observable<void> = this.onBroadcastGapChanged.asObservable();
    public onBroadcastGapUpdated$: Observable<void> = this.onBroadcastGapUpdated.asObservable();
    public chromecastAvailable$: Observable<boolean> = this.mediaPlayerHalService.chromecastAvailable$?.asObservable() || of(undefined);
    public chromecastConnected$: Observable<boolean> = this.mediaPlayerHalService.chromecastConnected$?.asObservable() || of(undefined);
    public airplayAvailable$: Observable<boolean> = this.mediaPlayerHalService.airplayAvailable$?.asObservable() || of(undefined);
    public airplayConnected$: Observable<boolean> = this.mediaPlayerHalService.airplayConnected$?.asObservable() || of(undefined);

    // SO
    private subscription: SubscriptionObject = {};
    private epgLiveSubscription: Subscription;
    private extension: string = '.m3u8';
    private protectingStartTime: number;

    constructor(
        private coreService: CoreService,
        private channelService: ChannelService,
        private componentRegisterService: ComponentRegisterService,
        private parentalControlService: ParentalControlService,
        private notificationService: NotificationService,
        private translateService: TranslateService,
        private restrictionService: RestrictionService,
        private authService: AuthService,
        private ngZone: NgZone,
        private httpClient: HttpClient,
        private settingsService: SettingsService,
        private portalSettingsService: PortalSettingsService,
        private generalService: GeneralService,
        private loggingService: LoggingService,
        private tileService: TileService,
        private mediaService: MediaService,
        private watchedService: WatchedService,
        private broadcastGapsV3Service: BroadcastGapsV3Service,
        private profileService: ProfileService,
        private preferredChannelTrackService: PreferredChannelTrackService,
        @Inject('PlatformHalService') private platformHalService: PlatformHal,
        @Inject('MediaPlayerHalService') private mediaPlayerHalService: MediaPlayerHalInterface) {
    }

    /* Media player public API */
    public playLive(channelId: number, config?: MediaPlayerConfig): Observable<any> {
        this.init(MediaPlayerTypes.CHANNEL, config);
        if (!channelId) {
            return this.error({ code: MediaPlayerErrors.MISSING_CHANNEL_ID });
        }
        const channel = this.channelService.getChannel(channelId);
        if (!this.getMcast(channel) && this.isChannelMissing(channel)) {
            return this.error({ code: MediaPlayerErrors.MISSING_CHANNEL });
        }
        if (this.isSessionPlayRestricted()) {
            return this.error({ code: MediaPlayerErrors.SESSION_PLAY_RESTRICTED });
        }
        if (this.isCellPlayRestricted()) {
            return this.error({ code: MediaPlayerErrors.CELL_PLAY_RESTRICTED });
        }
        const now = Date.now();
        if (this.isChannelParentalRestricted(channel)) {
            this.lockPlayer(MediaPlayerRestrictions.PARENTAL);
        } else if (this.isBroadcastRestricted(channel, now)) {
            this.lockPlayer(MediaPlayerRestrictions.BROADCAST);
        }
        this.initEpgLiveFetching();
        this.initBroadcastChecking(channel);
        if (!this.isSeeking()) {
            this.setStartTime(now);
        } else {
            this.watchingTime = now;
            this.onPlayerTimerRefresh.next();
        }
        this.channel = channel;
        this.mediaPlayerParams = this.generateMediaPlayerParams({
            type: 'live'
        });
        this.changeUrl();
        const epgLiveTile = this.getEpgLive(channel);
        if (epgLiveTile) {
            this.setStartTile(epgLiveTile);
        }
        this.requestTilesByStartTime();
        this.initBroadcastGapFetching();
        this.initBroadcastGapsChecking();
        return this.requestPlayer(this.play());
    }

    public playTs(channelId: number, startTime: number, config?: MediaPlayerConfig, first: boolean = false): Observable<any> {
        this.init(MediaPlayerTypes.CHANNEL, config);
        if (!channelId) {
            return this.error({ code: MediaPlayerErrors.MISSING_CHANNEL_ID });
        }
        if (this.isSessionPlayRestricted()) {
            return this.error({ code: MediaPlayerErrors.SESSION_PLAY_RESTRICTED });
        }
        if (this.isCellPlayRestricted()) {
            return this.error({ code: MediaPlayerErrors.CELL_PLAY_RESTRICTED });
        }
        // check protected interval
        const time = Date.now();
        // if starting in protected interval
        if (time - startTime < this.PROTECTED_TIME) {
            // add change URL
            return this.playLive(channelId);
        }
        const channel = this.channelService.getChannel(channelId);
        if (this.isChannelMissing(channel)) {
            return this.error({ code: MediaPlayerErrors.MISSING_CHANNEL });
        }
        if (channel.timeshiftTime === 0) {
            // add change URL
            return this.playLive(channelId);
        }
        if (this.isTsPlayRestricted(channel, startTime)) {
            startTime = time - channel.timeshiftTime;
        }
        if (this.isChannelParentalRestricted(channel)) {
            this.lockPlayer(MediaPlayerRestrictions.PARENTAL);
        } else if (this.isBroadcastRestricted(channel, startTime)) {
            this.lockPlayer(MediaPlayerRestrictions.BROADCAST);
        }
        this.initEpgLiveFetching();
        this.initBroadcastChecking(channel);
        if (!this.isSeeking()) {
            this.setStartTime(startTime);
        } else {
            this.watchingTime = startTime;
            this.onPlayerTimerRefresh.next();
        }
        this.channel = channel;
        this.mediaPlayerParams = this.generateMediaPlayerParams({
            type: 'ts',
            start: startTime,
            end: this.mediaPlayerConfig.endTime
        });
        this.changeUrl();
        this.requestTilesByStartTime(startTime);
        this.initBroadcastGapFetching();
        this.initBroadcastGapsChecking();
        return this.requestPlayer(this.play());
    }

    public playNpvr(npvrId: number, startTime: number, absoluteTime: boolean = false, config?: MediaPlayerConfig): Observable<any> {
        this.init(MediaPlayerTypes.NPVR, config);
        if (!npvrId) {
            return this.error({ code: MediaPlayerErrors.MISSING_NPVR_ID });
        }
        if (this.isSessionPlayRestricted()) {
            return this.error({ code: MediaPlayerErrors.SESSION_PLAY_RESTRICTED });
        }
        if (this.isCellPlayRestricted()) {
            return this.error({ code: MediaPlayerErrors.CELL_PLAY_RESTRICTED });
        }
        return this.requestPlayer(
            this.requestMediaTile(npvrId, MediaTypes.NPVR).pipe(
                switchMap((tile: MediaTile) => {
                    if (this.isNpvrMissing(tile)) {
                        return this.error({ code: MediaPlayerErrors.MISSING_NPVR });
                    }
                    if (this.isNpvrParentalRestricted(tile)) {
                        this.lockPlayer(MediaPlayerRestrictions.PARENTAL);
                    }
                    const channel = this.channelService.getChannel(tile.channelId);
                    if (this.isChannelMissing(channel)) {
                        return this.error({ code: MediaPlayerErrors.MISSING_CHANNEL });
                    }
                    this.channel = channel;
                    if (absoluteTime) {
                        startTime = startTime - tile.raw.start;
                    }
                    tile.watched = this.tileService.getTileWatched(tile, { progressCheck: true });
                    startTime = this.getMediaStartTime(tile, startTime);
                    if (!this.isSeeking()) {
                        this.setStartTime(startTime);
                    } else {
                        this.watchingTime = startTime;
                        this.onPlayerTimerRefresh.next();
                    }
                    this.setStartTile(tile);
                    this.mediaTile = tile;
                    this.mediaPlayerParams = this.generateMediaPlayerParams({
                        type: 'ts',
                        start: startTime,
                        end: this.getEndTimeWithOverlap(tile)
                    });
                    this.changeUrl();
                    this.initBroadcastGapFetching();
                    this.initBroadcastGapsChecking();
                    return of(null);
                }),
                switchMap(() => {
                    return this.play();
                }),
                catchError((e) => {
                    this.notificationService.show(this.translateService.instant('NOTIFICATIONS.MEDIA_PLAYER.NPVR_NOT_FOUND'));
                    return this.error({ code: MediaPlayerErrors.MISSING_NPVR, detail: e });
                })
            ));
    }

    public playEpisode(episodeId: number, startTime: number, absoluteTime: boolean = false, config?: MediaPlayerConfig): Observable<any> {
        this.init(MediaPlayerTypes.EPISODE, config);
        if (!episodeId) {
            return this.error({ code: MediaPlayerErrors.MISSING_EPISODE_ID });
        }
        if (this.isSessionPlayRestricted()) {
            return this.error({ code: MediaPlayerErrors.SESSION_PLAY_RESTRICTED });
        }
        if (this.isCellPlayRestricted()) {
            return this.error({ code: MediaPlayerErrors.CELL_PLAY_RESTRICTED });
        }
        return this.requestPlayer(
            this.requestMediaTile(episodeId, MediaTypes.EPISODE).pipe(
                switchMap((tile: MediaTile) => {
                    if (this.isEpisodeMissing(tile)) {
                        return this.error({ code: MediaPlayerErrors.MISSING_EPISODE });
                    }
                    if (this.isEpisodePlayableRestricted(tile)) {
                        return this.error({ code: MediaPlayerErrors.EPISODE_PLAYABLE_RESTRICTED });
                    }
                    const channel = this.channelService.getChannel(tile.channelId);
                    if (this.isChannelMissing(channel)) {
                        return this.error({ code: MediaPlayerErrors.MISSING_CHANNEL });
                    }
                    if (this.isEpisodeParentalRestricted(tile)) {
                        this.lockPlayer(MediaPlayerRestrictions.PARENTAL);
                    }
                    if (absoluteTime) {
                        startTime = startTime - tile.raw.start;
                    }
                    this.channel = this.channelService.getChannel(tile.channelId);
                    tile.watched = this.tileService.getTileWatched(tile, { progressCheck: true });
                    startTime = this.getMediaStartTime(tile, startTime);
                    if (!this.isSeeking()) {
                        this.setStartTime(startTime);
                    } else {
                        this.watchingTime = startTime;
                        this.onPlayerTimerRefresh.next();
                    }
                    this.setStartTile(tile);
                    this.mediaTile = tile;
                    this.mediaPlayerParams = this.generateMediaPlayerParams({
                        type: 'ts',
                        start: startTime,
                        end: this.getEndTimeWithOverlap(tile)
                    });
                    this.changeUrl();
                    this.initBroadcastGapFetching();
                    this.initBroadcastGapsChecking();
                    return of(null);
                }),
                switchMap(() => {
                    return this.play();
                }),
                catchError((e) => {
                    this.notificationService.show(this.translateService.instant('NOTIFICATIONS.MEDIA_PLAYER.EPISODE_NOT_FOUND'));
                    return this.error({ code: MediaPlayerErrors.MISSING_EPISODE, detail: e });
                })
            ));
    }

    public playVod(vodId: number, startTime: number, config?: MediaPlayerConfig): Observable<any> {
        this.init(MediaPlayerTypes.VOD, config);
        if (!vodId) {
            return this.error({ code: MediaPlayerErrors.MISSING_VOD_ID });
        }
        if (this.isSessionRestricted()) {
            return this.error({ code: MediaPlayerErrors.SESSION_PLAY_RESTRICTED });
        }
        if (this.isCellPlayRestricted()) {
            return this.error({ code: MediaPlayerErrors.CELL_PLAY_RESTRICTED });
        }
        return this.requestPlayer(
            this.requestMediaTile(vodId, MediaTypes.VOD).pipe(
                tap((tile: MediaTile) => {
                    if (this.isVodMissing(tile)) {
                        return this.error({ code: MediaPlayerErrors.MISSING_VOD });
                    }
                    if (this.isVodBoughtRestricted(tile)) {
                        return this.error({ code: MediaPlayerErrors.VOD_BOUGTH_RESTRICTED });
                    }
                    if (this.isVodParentalRestricted(tile)) {
                        this.lockPlayer(MediaPlayerRestrictions.PARENTAL);
                    }
                    tile.watched = this.tileService.getTileWatched(tile, { progressCheck: true });
                    startTime = this.getMediaStartTime(tile, startTime);
                    if (!this.isSeeking()) {
                        this.setStartTime(startTime);
                    } else {
                        this.watchingTime = startTime;
                        this.onPlayerTimerRefresh.next();
                    }
                    this.setStartTile(tile);
                    this.mediaTile = tile;
                    this.mediaPlayerParams = this.generateMediaPlayerParams({
                        type: 'vod',
                        start: startTime,
                        end: this.getEndTimeWithOverlap(tile)
                    });
                    this.changeUrl();
                }),
                switchMap(() => {
                    return this.play();
                }),
                catchError((e) => {
                    this.notificationService.show(this.translateService.instant('NOTIFICATIONS.MEDIA_PLAYER.VOD_NOT_FOUND'));
                    return this.error({ code: MediaPlayerErrors.MISSING_VOD, detail: e });
                })
            ));
    }

    public playUrl(url: string, params?: any) {
        this.init(MediaPlayerTypes.URL, params);
        console.log('streamUrl');
        console.log(url);
        return this.requestPlayer(from(this.mediaPlayerHalService.play(url, params)));
    }

    public unpause(): Observable<any> {
        if (this.pausedTime === undefined) {
            console.error('Can\'t unpause, refreshing player');
            return this.refreshPlayer();
        }
        switch (this.mediaPlayerType) {
            case MediaPlayerTypes.CHANNEL:
                return this.playTs(this.channel.id, this.pausedTime);
            case MediaPlayerTypes.NPVR:
                return this.playNpvr(this.cursorTile.id, this.pausedTime, true);
            case MediaPlayerTypes.EPISODE:
                return this.playEpisode(this.cursorTile.id, this.pausedTime, true);
            case MediaPlayerTypes.VOD:
                return this.playVod(this.cursorTile.id, this.pausedTime);
        }
    }

    public pause(): Observable<any> {
        this.destroyPlayer();
        this.pausedTime = Math.min(this.watchingTime, Date.now() - this.PROTECTED_TIME);
        this.timersStuck.cursor = true;
        this.timersStuck.watched = true;
        this.logWithPlayback(LogEvents.PAUSE);
        return from(this.mediaPlayerHalService.pause())
            .pipe(
                catchError((error) => {
                    if (error) {
                        console.error(error);
                    }
                    // ignore stop errors
                    return of(undefined);
                }),
                tap(() => this.onPause.next())
            );
    }

    public stop(emit: boolean = true, log: boolean = true): Observable<any> {
        this.destroyPlayer();
        this.stopped = true;
        this.timersStuck.cursor = true;
        this.timersStuck.watched = true;
        if (log) {
            this.logWithPlayback(LogEvents.STOP, { requirePlayback: false });
        }
        return from(this.mediaPlayerHalService.stop())
            .pipe(
                catchError((error) => {
                    if (error) {
                        console.error(error);
                    }
                    // ignore stop errors
                    return of(undefined);
                }),
                tap(() => {
                    if (emit) {
                        this.onStop.next();
                    }
                })
            );
    }

    public sleep(): Observable<any> {
        this.sleeping = true;
        this.parentalControlService.lock();
        SOM.clearSubscriptionsObject(this.subscription);
        this.subscription = {};
        return this.stop(false);
    }

    public wakeUp(): Observable<any> {
        return this.refreshPlayer();
    }

    public playBackground(): Observable<any> {
        if (!this.cert) {
            return of(undefined);
        }
        if (!this.mediaPlayerParams) {
            return of(undefined);
        }
        this.mediaPlayerParams.start = this.watchingTime;
        const url = this.generateStreamUrl(this.mediaPlayerParams, this.cert);
        return from(this.mediaPlayerHalService.playBackground(url));
    }

    playForeground(): Observable<any> {
        if (!this.cert) {
            return of(undefined);
        }
        if (!this.mediaPlayerParams) {
            return of(undefined);
        }
        this.mediaPlayerParams.start = this.watchingTime;
        const url = this.generateStreamUrl(this.mediaPlayerParams, this.cert);
        return from(this.mediaPlayerHalService.playForeground(url)).pipe(
            switchMap(() => this.tracksUpdated$),
            filter((loaded) => loaded),
            take(1),
            tap(() => {
                this.activateAudioSubtitleTracks().then(() => {
                    // send PLAY after tracks ready
                    this.logWithPlayback(LogEvents.PLAY);
                });
            })
        );
    }

    public seek(delta: number): void {
        const now = Date.now();
        if (delta < 0 || this.cursorTime < now - this.PROTECTED_TIME) {
            const direction = delta > 0 ? 1 : -1;
            // time shift restrictions
            if (this.mediaPlayerType === MediaPlayerTypes.CHANNEL) {
                if (now - (this.cursorTime + delta) > this.channel.timeshiftTime) {
                    this.notificationService.show(this.translateService.instant('NOTIFICATIONS.MEDIA_PLAYER.TS_RESTRICTED'));
                    return;
                }
            }
            const seekTime = this.getBroadcastGapSeekTime({ from: this.cursorTime, to: this.cursorTime + delta });
            if (seekTime === -1) {
                this.onPlayerTimerRefresh.next();
                return;
            }
            this.timersStuck.cursor = true;
            const startTime = this.getSeekTime(seekTime, direction, now);
            this.cursorTime = startTime;
            this.seeking = true;
            this.checkCursorTime();
            this.onSeeking.next({
                startTime: startTime,
                direction: direction
            });
        }
    }

    public seekToPosition(startTime: number, direction: number = 1, absoluteTime: boolean = true): Observable<any> {
        const seekTime = this.getBroadcastGapSeekTime({ from: this.cursorTime, to: startTime });
        if (seekTime === -1) {
            this.onPlayerTimerRefresh.next();
            return of(undefined);
        }
        this.cursorTime = this.getSeekTime(seekTime, direction);
        this.checkCursorTime();
        if (this.cursorTile) {
            this.cursorTile.watched = {
                position: this.tileService.hasAbsoluteWatchedTime(this.cursorTile.mediaType) ?
                    this.cursorTime : this.cursorTime - this.cursorTile.raw.start
            };
            this.watchedService.seekWatched(this.tileService.getWatched(this.cursorTile, this.mediaPlayerType));
        }
        if (this.requestingContinuingPlay()) {
            return of(undefined);
        }
        this.stop(false).subscribe();
        switch (this.mediaPlayerType) {
            case MediaPlayerTypes.CHANNEL:
                if (this.isTimeShiftStartTime(this.cursorTime)) {
                    return this.playTs(this.channel.id, this.cursorTime);
                } else {
                    return this.playLive(this.channel.id);
                }
            case MediaPlayerTypes.NPVR:
                return this.playNpvr(this.cursorTile.id, this.cursorTime, absoluteTime);
            case MediaPlayerTypes.EPISODE:
                return this.playEpisode(this.cursorTile.id, this.cursorTime, absoluteTime);
            case MediaPlayerTypes.VOD:
                return this.playVod(this.cursorTile.id, this.cursorTime);
        }
    }

    public startOver(): Observable<any> {
        if (!this.cursorTile) {
            return this.error({ code: MediaPlayerErrors.CANT_START_OVER });
        }
        switch (this.mediaPlayerType) {
            case MediaPlayerTypes.CHANNEL:
                return this.playTs(this.channel.id, this.cursorTile.raw.start);
            case MediaPlayerTypes.NPVR:
                return this.playNpvr(this.cursorTile.id, this.cursorTile.raw.start, true);
            case MediaPlayerTypes.EPISODE:
                return this.playEpisode(this.cursorTile.id, this.cursorTile.raw.start, true);
            case MediaPlayerTypes.VOD:
                return this.playVod(this.cursorTile.id, this.cursorTile.raw.start);
        }
    }

    public channelUp(): Observable<any> {
        if (this.mediaPlayerType === MediaPlayerTypes.VOD) {
            return of(undefined);
        }
        if (!this.channel) {
            return this.error({ code: MediaPlayerErrors.CANT_CHANGE_CHANNEL });
        }
        return this.changeChannel(this.channelService.getChannelAfter(this.channel.id));
    }

    public channelDown(): Observable<any> {
        if (this.mediaPlayerType === MediaPlayerTypes.VOD) {
            return of(undefined);
        }
        if (!this.channel) {
            return this.error({ code: MediaPlayerErrors.CANT_CHANGE_CHANNEL });
        }
        return this.changeChannel(this.channelService.getChannelBefore(this.channel.id));
    }

    public changeChannel(channel: Channel): Observable<any> {
        if (this.mediaPlayerType === MediaPlayerTypes.VOD) {
            return of(undefined);
        }
        if (!channel || !this.channel) {
            return this.error({ code: MediaPlayerErrors.CANT_CHANGE_CHANNEL });
        }
        if (channel.id === this.channel.id) {
            return of(null);
        }
        if (this.mediaPlayerHalService.refreshTracks) {
            this.mediaPlayerHalService.refreshTracks();
        }
        return this.stop(false).pipe(switchMap(() => this.playLive(channel.id)));
    }

    public prevEntity(): Observable<any> {
        return this.seekToPosition(this.cursorTile.raw.start - 30000, -1);
    }

    public nextEntity(): Observable<any> {
        return this.seekToPosition(this.cursorTile.raw.end);
    }

    public refreshPlayer(offset: number = 0): Observable<any> {
        if (this.watchingTime === undefined) {
            return this.error({ code: MediaPlayerErrors.CANT_REFRESH_PLAYER });
        }
        switch (this.mediaPlayerType) {
            case MediaPlayerTypes.CHANNEL:
                if (this.isLive()) {
                    return this.playLive(this.channel?.id);
                } else {
                    return this.playTs(this.channel?.id, this.watchingTime + offset);
                }
            case MediaPlayerTypes.NPVR:
                return this.playNpvr(this.cursorTile?.id, Math.max(this.watchingTime + offset, 0), true);
            case MediaPlayerTypes.EPISODE:
                return this.playEpisode(this.cursorTile?.id, Math.max(this.watchingTime + offset, 0), true);
            case MediaPlayerTypes.VOD:
                return this.playVod(this.cursorTile?.id, Math.max(this.watchingTime + offset, 0));
        }
    }

    public activateAudioTrack(id: number, log: boolean = true, store: boolean = true): Promise<void> {
        return Promise.resolve(this.mediaPlayerHalService.activateAudioTrack(id)).then(() => {
            if (store) {
                this.storePreferredAudio();
            }
            if (log) {
                this.logWithPlayback();
            }
        });
    }

    public activateSubtitleTrack(id: number, log: boolean = true, store: boolean = true): Promise<void> {
        return Promise.resolve(this.mediaPlayerHalService.activateSubtitleTrack(id)).then(() => {
            if (store) {
                this.storePreferredSubtitle();
            }
            if (log) {
                this.logWithPlayback();
            }
        });
    }

    public activateAudioTrackByLang(lang: string, layout?: string): void {
        const audioTracks = this.getAudioTracks();
        const track = audioTracks.find(trackItem => trackItem.language === lang && (!layout || layout === trackItem.layout));
        if (track) {
            this.activateAudioTrack(track.id);
        }
    }

    public activateSubtitleTrackByLang(lang: string): void {
        const subtitleTracks = this.getSubtitleTracks();
        const track = subtitleTracks.find(trackItem => trackItem.language === lang);
        this.activateSubtitleTrack(track?.id);
    }


    public isMuted(): boolean {
        if (this.mediaPlayerHalService.getMuted) {
            return this.mediaPlayerHalService.getMuted();
        }
    }

    public getVolume(): number {
        return this.mediaPlayerHalService.getVolume();
    }

    public setVolume(volume: number): void {
        if (this.mediaPlayerHalService.setVolume) {
            this.mediaPlayerHalService.setVolume(volume);
        }
    }

    public setMute(muted: boolean): void {
        if (this.mediaPlayerHalService.setMute) {
            this.mediaPlayerHalService.setMute(muted);
        }
    }

    public toggleFullscreen(): void {
        this.onToggleFullscreen$.next(!this.onToggleFullscreen$.getValue());
    }

    public requestChromecast() {
        this.mediaPlayerHalService.requestChromecast();
    }

    public requestAirplay() {
        this.mediaPlayerHalService.requestAirplay();
    }

    public destroy() {
        this.stop(false).subscribe();
        this.mediaPlayerHalService.destroy();
        this.publishWatching(true).subscribe();
        SOM.clearSubscriptionsObject(this.subscription);
        SOM.clearSubscriptions(this.epgLiveSubscription);
    }

    /* General */
    private init(mediaPlayerType: MediaPlayerTypes, config?: any): void {
        this.mediaPlayerType = mediaPlayerType;
        this.mediaPlayerConfig = config || this.mediaPlayerConfig || {};
        this.mediaPlayerParams = undefined;
        this.signIdent = undefined;
        this.channel = undefined;
        this.settings = this.settingsService.getParsedSettings();
        this.portalSettings = this.portalSettingsService.getPortalSettings();
        this.cert = undefined;

        // this.tiles = undefined;
        this.cursorTile = undefined;
        this.watchingTile = undefined;
        // this.epgLive = undefined;

        this.liveTime = undefined;
        this.watchingTime = undefined;
        this.cursorTime = undefined;
        this.timersStuck = {
            cursor: true,
            watched: true
        };

        this.pausedTime = undefined;
        this.stopped = undefined;
        this.playerLoaded = undefined;
        this.seeking = undefined;
        this.sleeping = undefined;
        this.lastLogPrevented = undefined;
        if (this.isLocked()) {
            this.unlockPlayer();
        }
        SOM.clearSubscriptionsObject(this.subscription);
        this.subscription = {};

        if (!this.mediaPlayerConfig.onlyPlay) {
            this.initPlayerTimer();
            this.initSeeking();
            this.initLayoutSwitchWatching();
            this.initPublishWatching();
            this.initEndWatch();
            this.initErrorsWatch();
            this.initAirplayWatch();
        }
        if (this.mediaPlayerConfig.refreshTracks && this.mediaPlayerHalService.refreshTracks) {
            this.mediaPlayerHalService.refreshTracks();
        }
    }

    private requestPlayer(player$: Observable<any>) {
        return new Observable<any>(subscriber => {
            SOM.clearSubscriptions(this.subscription.player);
            this.onPlayerInitialized.next();
            this.subscription.player = player$.subscribe(() => {
                subscriber.next();
            }, (e) => {
                console.error(e);
            });
        });
    }

    private destroyPlayer() {
        SOM.clearSubscriptions(this.subscription.player);
        this.subscription.player = undefined;
    }

    private beforePlay() {
        if (this.isLive() && this.getMcast()) {
            return of(this.channel.source);
        } else {
            const prepare$ = !this.cert ? this.sign(this.mediaPlayerParams) : of(undefined);
            return prepare$.pipe(
                switchMap(() => {
                    if (!this.cert) {
                        return this.error({ code: MediaPlayerErrors.MISSING_CERT });
                    }
                    return of(this.generateStreamUrl(this.mediaPlayerParams, this.cert));
                })
            );
        }
    }

    private play(): Observable<any> {
        if (this.isLocked()) {
            // clear stucks
            this.timersStuck = {};
            return of(undefined);
        }
        return this.beforePlay().pipe(
            switchMap((streamUrl) => {
                const params: any = { ...this.mediaPlayerConfig };
                params.pipe = '';
                // clear proxy, for sure
                params.proxy = undefined;
                if (streamUrl.match(/^igmp:\/\//)) {
                    params.proxy = false;
                }
                if (this.generalService.isTizen2015()) {
                    if (this.isLive()) {
                        params.pipe = '&startFrom=-4';
                    } else {
                        params.pipe = '&startFrom=0';
                    }
                }
                if (hal.platform === 'TV.ARRIS' && hal.deviceType === DeviceTypes.STB) {
                    params.pipe += '|buftime=1000';
                }
                //streamUrl = "https://11-ucd01-3201-staging.tv.cetin.cz:8443/bpk-tv/CNN_1082/output0/index.m3u8?begin=20240313T070000Z&end=20240313T080000Z&accountId=8388&deviceType=0&subscriptionType=0&primaryToken=9e23c66d1713df24_13621c15f36b6d6986cac98769350c88";
                console.log('streamUrl');
                console.log(streamUrl + params.pipe);
                return this.playWithRetry(streamUrl, this.cert, params);
            }),
            tap(() => {
                this.playerLoaded = true;
                // clear stucks
                this.timersStuck = {};
                console.log('Player playing...');
                this.onPlayStarted.next();
                // refresh tracks loaded
                if (!this.mediaPlayerConfig.forceMute) {
                    this.subscription.tracksUpdated = this.tracksUpdated$
                        .pipe(
                            filter((loaded) => loaded),
                            take(1)
                        )
                        .subscribe(() => {
                            this.activateAudioSubtitleTracks().then(() => {
                                // send PLAY after tracks ready
                                this.logWithPlayback(LogEvents.PLAY);
                            });
                        });
                    // TODO: try find better solution, this is little tricky... :(
                } else if (hal.platform === 'TV.ARRIS') {
                    this.tracksUpdated$
                        .pipe(
                            filter((loaded) => loaded),
                            take(1)
                        )
                        .subscribe(() => {
                            const audioTracks = this.getAudioTracks();

                            // find some 2.0 track for preventing 5.1 to be default
                            const someStereoTrack = audioTracks.find(audioTrack => !audioTrack.layout || audioTrack.layout === '2.0');
                            if (someStereoTrack) {
                                // activate 2.0 track
                                this.activateAudioTrack(someStereoTrack.id, false, false);
                            }
                        });
                }
            }),
            catchError((e) => {
                this.notificationService.show(this.translateService.instant('NOTIFICATIONS.MEDIA_PLAYER.CANT_PLAY'));
                return this.error({ code: MediaPlayerErrors.CANT_PLAY, detail: e });
            }));
    }

    private generateMediaPlayerParams(mediaPlayerParams: MediaPlayerParams) {
        // undefined values are ignored
        mediaPlayerParams = {
            resolution: this.generalService.isTizen2015() ? 0 : 2,
            dialect: hal.mediaPlayer.playbackUrlType,
            forcelive: 1,
            fmt: hal.mediaPlayer.playbackUrlFmt || undefined,
            dd: hal.mediaPlayer.dolbyDigital ? 1 : 0,
            fpsint: hal.mediaPlayer.fpsInt ? 1 : 0,
            epd: hal.mediaPlayer.epd ? 1 : 0,
            lsps: this.getMaxLsps(
                this.platformHalService.getFeatures()[ 'maxLumaSamplesPerSec' ],
                this.portalSettings.mediaPlayer.maxLumaSamplesPerSec),
            reqRate: this.portalSettings.mediaPlayer.reqRate || undefined,
            dt: hal.deviceType,
            maxbitrate: this.getMaxBitrate(this.settings.bitrate, this.portalSettings.mediaPlayer.maxBitrateLimit) || undefined,
            hevc: this.getHevc() ? 1 : 0,
            wv: this.getWV() ? 1 : 0,
            ...mediaPlayerParams
        };
        if (this.generalService.isTizen2015()) {
            mediaPlayerParams = {
                ...mediaPlayerParams,
                dialect: 'cached',
                fmt: undefined,
                dd: 0,
                wv: 0
            };
        }
        return mediaPlayerParams;
    }

    private generateStreamUrl(mediaPlayerParams: MediaPlayerParams, cert: Cert): string {
        let httpParams = new HttpParams();
        for (const key in mediaPlayerParams) {
            if (mediaPlayerParams.hasOwnProperty(key) && mediaPlayerParams[ key ] !== undefined) {
                httpParams = httpParams.set(key, mediaPlayerParams[ key ]);
            }
        }
        httpParams = httpParams
            .set('expires', cert.sign.expires.toString())
            .set('sign', cert.sign.sign);

        let baseUrl = '';
        switch (hal.mediaPlayer.playbackUrlBaseType) {
            case 'baseUrl':
                baseUrl = cert.baseUrl;
                break;
            case 'baseUrlPlain':
                baseUrl = cert.baseUrlPlain;
                break;
            case 'baseUrlPlainCached':
                baseUrl = cert.baseUrlPlainCached;
                break;
        }
        if (this.generalService.isTizen2015()) {
            baseUrl = cert.baseUrlPlainCached;
        }
        const extension = this.generalService.isTizen2015() ? '' : this.extension;
        return `${ baseUrl }${ this.signIdent }/stream${ extension }?${ httpParams.toString() }`;
    }

    private setStartTime(startTime: number = Date.now()) {
        this.watchingTime = startTime;
        this.cursorTime = startTime;
        this.onPlayerTimerRefresh.next();
    }

    private setStartTile(tile: MediaTile) {
        this.cursorTile = tile;
        this.onTileChanged.next();
        this.onPlayerTimerRefresh.next();
    }

    private getSeekTime(startTime: number, direction: number, time = Date.now()) {
        if (this.mediaPlayerType === MediaPlayerTypes.CHANNEL) {
            // if seeked to protected interval
            if (time - startTime < this.PROTECTED_TIME) {
                // if seek right, return live
                // if seek left, return time - interval
                return direction > 0 ? time : time - this.PROTECTED_TIME;
            }
            if (!this.cursorTile) {
                return startTime;
            }
            if (startTime < this.cursorTile.raw.start || startTime > this.cursorTile.raw.end) {
                const tile = this.getTileByStartTime(startTime);
                if (tile) {
                    // is tile from past
                    if (tile === this.tiles.past) {
                        return this.tiles.past.raw.end - 10000;
                    }
                    // is tile from future
                    if (tile === this.tiles.future) {
                        return this.tiles.future.raw.start;
                    }
                }
                // not past, not future, stuck on start/end position
                return startTime < this.cursorTile.raw.start ? this.cursorTile.raw.start : this.cursorTile.raw.end;
            }
        } else {
            // pretending seeking before LIVE, e.g. in NPVR
            // if seeked to protected interval
            if (time - startTime < this.PROTECTED_TIME) {
                return time - this.PROTECTED_TIME;
            }
            const overlap = this.tileService.getOverlap(this.cursorTile);
            if (startTime < this.cursorTile.raw.start - overlap.before) {
                return this.cursorTile.raw.start - overlap.before;
            }
            if (startTime > this.cursorTile.raw.end + overlap.after) {
                return this.cursorTile.raw.end + overlap.after;
            }
        }
        return startTime;
    }

    private getMediaStartTime(tile, startTime) {
        if (!tile) {
            return;
        }
        // Relative time from url
        if (startTime !== undefined) {
            return tile.raw.start + startTime;
        } else if (tile.watched) {
            if (this.tileService.hasAbsoluteWatchedTime(tile.mediaType)) {
                return tile.watched.position;
            } else {
                return tile.raw.start + tile.watched.position;
            }
        }
        return tile.raw.start;
    }

    private getStartTimeWithOverlap(tile) {
        const overlap = this.tileService.getOverlap(tile);
        return tile.raw.start - overlap.before;
    }

    private getEndTimeWithOverlap(tile) {
        const overlap = this.tileService.getOverlap(tile);
        return tile.raw.end + overlap.after;
    }

    private sign(mediaPlayerParams: MediaPlayerParams): Observable<Cert> {
        this.signIdent = this.getSignIdent();
        if (!this.signIdent) {
            return this.error({ code: MediaPlayerErrors.MISSING_SIGN_IDENT });
        }
        return this.signRequest({ ident: this.signIdent, type: mediaPlayerParams.type })
            .pipe(
                tap((cert) => this.cert = cert),
                catchError((e) => this.error({ code: MediaPlayerErrors.SIGN_FAILED, detail: e }))
            );
    }

    private playWithRetry(streamUrl: string, cert: Cert, params?: any) {
        return of(undefined)
            .pipe(
                switchMap(() => {
                    const play = this.mediaPlayerHalService.play(streamUrl, { cert: cert, ...params })
                        .then(() => {
                            // if player paused during play, pause it
                            // promise can't be cancelled, so this is only solution
                            if (this.isPaused()) {
                                this.mediaPlayerHalService.pause();
                            }
                        });
                    return play || of(undefined);
                }),
                retryWhen((errors: Observable<any>) => errors
                    .pipe(
                        mergeMap((e, i) => {
                            // retries
                            if (i >= this.PLAY_RETRY_ATTEMPTS) {
                                return this.error({ code: MediaPlayerErrors.PLAY_RETRIES_RUN_OUT, detail: e });
                            }
                            console.log('Player load error, retrying attempt', i + 1);
                            return timer(e?.code === 0 ? 100 : 3000);
                        })
                    ))
            );
    }

    private lockPlayer(reason?: MediaPlayerRestrictions) {
        this.stop(false).subscribe();
        this.lockedReason = reason;
        this.onLockUpdated.next();
    }

    private unlockPlayer() {
        this.lockedReason = undefined;
        this.onLockUpdated.next();
    }

    private requestTilesByStartTime(startTime: number = Date.now(), channel: Channel = this.channel) {
        // has actual data
        if (this.tiles?.now &&
            this.tiles.now.channelId === channel.id &&
            startTime >= this.tiles.now.raw.start && startTime < this.tiles.now.raw.end) {
            if (!this.cursorTile) {
                this.setStartTile(this.getTileByStartTime(startTime));
            }
            return;
        }
        // clear tiles
        this.tiles = {};
        // already fetching
        if (this.subscription.requestTilesByStartTime) {
            return;
        }
        this.subscription.requestTilesByStartTime = this.fetchMediaPlayerTiles(channel, startTime).subscribe(() => {
            if (!this.cursorTile) {
                this.setStartTile(this.getTileByStartTime(startTime));
            }
            this.subscription.requestTilesByStartTime = undefined;
        }, () => {
            this.subscription.requestTilesByStartTime = undefined;
        });
    }

    private requestContinuingEntity(watchingTile: MediaTile = this.watchingTile) {
        // already fetching
        if (this.subscription.requestContinuingEntity) {
            return;
        }
        this.subscription.requestContinuingEntity = this.fetchContinuingEntity(watchingTile)
            .pipe(
                catchError((e) => this.error({ code: MediaPlayerErrors.MISSING_CONTINUING_ENTITY, detail: e }))
            )
            .subscribe((mediaTile) => {
                SOM.clearSubscriptions(this.subscription.continuingPlay);
                switch (mediaTile.mediaType) {
                    case MediaTypes.EPG_ENTITY:
                        this.tiles = {
                            now: mediaTile
                        };
                        this.subscription.continuingPlay = this.playTs(mediaTile.channelId, mediaTile.raw.start)
                            .subscribe();
                        break;
                    case MediaTypes.EPISODE:
                        this.mediaTile = mediaTile;
                        this.subscription.continuingPlay = this.playEpisode(mediaTile.id, 0)
                            .subscribe();
                }
                this.subscription.requestContinuingEntity = undefined;
            });
    }

    private requestMediaTile(id: number, mediaType: MediaTypes): Observable<MediaTile> {
        if (this.mediaTile) {
            if (this.mediaTile.mediaType === mediaType && this.mediaTile.id === id) {
                return of(this.mediaTile);
            }
        }
        return this.mediaService.getMedia(id, mediaType)
            .pipe(
                map(media => media?.tile)
            );
    }

    private checkTime() {
        this.checkWatchingTime();
        this.checkCursorTime();
    }

    private checkWatchingTime() {
        if (this.watchingTile) {
            const endTime = this.getEndTimeWithOverlap(this.watchingTile);
            if (this.watchingTime >= endTime) {
                if (this.isContinuingPlay()) {
                    this.watchingTime = endTime;
                    this.stop(false).subscribe();
                    this.requestContinuingEntity();
                    return;
                } else if ([ MediaPlayerTypes.NPVR, MediaPlayerTypes.EPISODE, MediaPlayerTypes.VOD ].indexOf(this.mediaPlayerType) >= 0) {
                    this.watchingTime = endTime;
                    this.stop().subscribe();
                    return;
                } else if (this.mediaPlayerType === MediaPlayerTypes.CHANNEL) {
                    this.logWithPlayback(LogEvents.STOP);
                    this.watchingTile = this.getTileByStartTime(this.watchingTime);
                    this.logWithPlayback(LogEvents.PLAY);
                    return;
                }
            }
            if (this.mediaPlayerType === MediaPlayerTypes.CHANNEL) {
                if (this.watchingTime > this.mediaPlayerParams.end) {
                    this.watchingTime = this.mediaPlayerParams.end;
                    this.stop().subscribe();
                    return;
                }
            }
        }
        if (this.mediaPlayerType === MediaPlayerTypes.CHANNEL) {
            this.watchingTile = this.getTileByStartTime(this.watchingTime);
        } else {
            this.watchingTile = this.cursorTile;
        }
    }

    private checkCursorTime() {
        if (!this.cursorTile) {
            return;
        }
        if (this.requestingContinuingPlay()) {
            return;
        }
        if (this.timeOutOfInterval(this.cursorTime, this.cursorTile)) {
            if (this.isContinuingPlay()) {
                const endTime = this.getEndTimeWithOverlap(this.cursorTile);
                if (this.cursorTime >= endTime) {
                    this.watchingTime = endTime;
                    this.stop(false).subscribe();
                    this.requestContinuingEntity();
                }
            } else if (this.mediaPlayerType === MediaPlayerTypes.CHANNEL) {
                this.onPlayerTimerRefresh.next();
                const tile = this.getTileByStartTime(this.cursorTime);
                if (tile) {
                    this.cursorTile = tile;
                    this.onTileChanged.next();
                }
                this.requestTilesByStartTime(this.cursorTime);
            }
        }
    }

    private getBroadcastGapSeekTime(interval: Interval): number {
        if (this.isAdsEnabled() && this.isObeySkippable() && interval.to > interval.from && this.cursorTile) {
            const startOver = this.broadcastGapsV3Service.isStartOver(this.cursorTile.raw.end);
            const nextBroadcastGapGroup = this.broadcastGapsV3Service.getActiveOrNextBroadcastGroup(this.channel.id, interval, startOver);
            if (nextBroadcastGapGroup) {
                this.notificationService.show(this.channel.gapSettings.skipDeniedMessage, {
                    cssClass: 'notification-transparent'
                });
                if (interval.from >= nextBroadcastGapGroup.datetimeFrom && interval.from < nextBroadcastGapGroup.datetimeTo) {
                    return -1;
                }
                return nextBroadcastGapGroup.datetimeFrom;
            }
        }
        return interval.to;
    }

    private changeUrl(params: MediaPlayerParams = this.mediaPlayerParams) {
        this.onChangeUrl.next(this.generateRouteParams(params));
    }

    private generateRouteParams(params: MediaPlayerParams): MediaPlayerParams {
        const routeParams = {};
        if (this.mediaPlayerType === MediaPlayerTypes.CHANNEL) {
            routeParams[ 'type' ] = params.type;
        }
        if (params.start) {
            if (params.type === 'ts') {
                if (this.mediaPlayerType === MediaPlayerTypes.CHANNEL) {
                    routeParams[ 'start' ] = params.start;
                    if (params.end) {
                        routeParams[ 'end' ] = params.end;
                    }
                } else {
                    const relativeStart = params.start - this.cursorTile.raw.start;
                    if (relativeStart) {
                        routeParams[ 'start' ] = relativeStart;
                    }
                }
            } else if (params.type === 'vod') {
                routeParams[ 'start' ] = params.start;
            }
        }
        return routeParams;
    }

    private storeWatchingTile(force: boolean = false) {
        if (!this.watchingTile) {
            return;
        }
        if (this.watchingTime === undefined) {
            return;
        }
        if (this.isTimeshiftDisabled()) {
            return;
        }
        this.watchingTile.state = this.tileService.detectTileState(this.watchingTile);
        this.watchingTile.watched = {
            position: this.tileService.hasAbsoluteWatchedTime(this.watchingTile.mediaType) ?
                this.watchingTime : this.watchingTime - this.watchingTile.raw.start
        };
        const watched = this.tileService.getWatched(this.watchingTile, this.mediaPlayerType);
        if (watched.position !== undefined) {
            this.watchedService.storeWatched(watched, force ? 0 : this.TS_PUBLISH_DELAY_LIMIT);
        }
    }

    private storePreferredAudio(audioTrack = this.getActiveAudioTrack()) {
        if (!this.channel) {
            return;
        }
        this.subscription.storePreferredAudioTrack =
            this.preferredChannelTrackService.storePreferredAudioTrack(this.channel.id, audioTrack, this.settings).subscribe();
    }

    private storePreferredSubtitle(subtitleTrack = this.getActiveSubtitleTrack()) {
        if (!this.channel) {
            return;
        }
        this.subscription.storePreferredSubtitleTrack =
            this.preferredChannelTrackService.storePreferredSubtitleTrack(this.channel.id, subtitleTrack, this.settings).subscribe();
    }

    private publishWatching(force: boolean = false): Observable<any> {
        // store watching tile (lock)
        this.storeWatchingTile(force);
        this.watchedService.clearDelayWatched(this.TS_PUBLISH_DELAY_LIMIT);
        const allWatched = this.watchedService.getWatched()
            .filter(watchedItem => !watchedItem.published)
            .map((watchedItem) => {
                return {
                    entityId: watchedItem.entityId,
                    playing: watchedItem.playing,
                    position: watchedItem.position,
                    insertIfNotFound: watchedItem.insertIfNotFound,
                    trace: watchedItem.trace
                };
            });
        if (allWatched.length > 0) {
            return this.httpClient.post(environment.apiUrl + 'watching/publish', allWatched)
                .pipe(
                    tap(() => {
                        allWatched.forEach((watchedItem) => {
                            this.watchedService.publishWatched(watchedItem);
                        });
                    }));
        }
        return of(undefined);
    }

    private error(error: MediaPlayerError): Observable<any> {
        console.error(error);
        switch (error.code) {
            case MediaPlayerErrors.AUTOPLAY_FAILED:
                return this.stop(false, false);
            case MediaPlayerErrors.MEDIA_PLAYER_HAL_ERROR:
                if (error.async) {
                    // try to revive play process by
                    if (this.refreshCounter < this.PLAY_RETRY_ATTEMPTS) {
                        this.refreshCounter++;
                        return this.refreshPlayer(this.refreshCounter === 1 ? 0 : (-1000)).pipe(tap(() => {
                            this.refreshCounter = 0;
                        }));
                    }
                } else {
                    return of(undefined);
                }
                break;
            case MediaPlayerErrors.DRM_FAILED:
                this.notificationService.show(this.translateService.instant('NOTIFICATIONS.GENERAL.DRM_ERROR'));
                break;
        }
        this.onError.next(error);
        return this.stop(false)
            .pipe(
                switchMap(() => throwError(error))
            );
    }

    /* Helpers */
    private isTimeShiftStartTime(startTime: number) {
        return startTime <= Date.now() - this.PROTECTED_TIME;
    }

    private timeOutOfInterval(time: number, tile: MediaTile): boolean {
        return time < this.getStartTimeWithOverlap(tile) || time >= this.getEndTimeWithOverlap(tile);
    }

    private isContinuingPlay() {
        return (this.settings.continuingPlay || this.mediaPlayerConfig.continuingPlay)
            && [ MediaPlayerTypes.CHANNEL, MediaPlayerTypes.EPISODE ].indexOf(this.mediaPlayerType) >= 0;
    }

    private requestingContinuingPlay() {
        return !!this.subscription.requestContinuingEntity;
    }

    /* Tracks */
    private activateAudioSubtitleTracks() {
        const audioTracks = this.getAudioTracks();
        const subtitleTracks = this.getSubtitleTracks();
        const activeSubtitleTrack = this.getActiveSubtitleTrack();
        let activeAudioTrack = null;

        activeAudioTrack = this.getActiveAudioTrack();        
        if (audioTracks && audioTracks.length > 0 && activeAudioTrack) {
            const track = audioTracks.find(audioTrack =>
                audioTrack.id === activeAudioTrack.id &&
                audioTrack.language === activeAudioTrack.language &&
                audioTrack.layout === activeAudioTrack.layout
            );
            if(!track) {
                activeAudioTrack = null;
            }
        }

        let prefAudioTrack, prefSubtitleTrack;
        if (activeAudioTrack) {
            prefAudioTrack = activeAudioTrack;
        } else if (this.mediaPlayerConfig.audioTrack) {
            const audioTrack = this.mediaPlayerConfig.audioTrack;
            prefAudioTrack = audioTracks.find(audioTrackItem =>
                audioTrackItem.language === audioTrack.language &&
                audioTrackItem.layout === audioTrack.layout);
        } else if (audioTracks && audioTracks.length > 0) {
            const channelAudioTrack = this.preferredChannelTrackService.getPreferredAudioTrack(this.channel?.id, this.settings);
            if (channelAudioTrack) {
                // first try also with layout
                if (channelAudioTrack.layout) {
                    prefAudioTrack = audioTracks.find(audioTrack =>
                        audioTrack.language === channelAudioTrack.language &&
                        audioTrack.layout === channelAudioTrack.layout
                    );
                }
                // not found, so try only language
                if (!prefAudioTrack) {
                    prefAudioTrack = audioTracks.find(audioTrack =>
                        audioTrack.language === channelAudioTrack.language
                    );
                }
            } else {
                const audioPrefs = [ this.settings.audioPrefLevel1, this.settings.audioPrefLevel2, this.settings.audioPrefLevel3 ];
                for (const audioPref of audioPrefs) {
                    prefAudioTrack = audioTracks.find(audioTrack =>
                        audioTrack.language === audioPref && (!audioTrack.layout || audioTrack.layout === '2.0'));
                    if (prefAudioTrack) {
                        break;
                    }
                }
            }
            prefAudioTrack = prefAudioTrack || audioTracks[ 0 ];
        }
        if (activeSubtitleTrack) {
            prefSubtitleTrack = activeSubtitleTrack;
        } else if (this.mediaPlayerConfig.subtitleTrack) {
            const subtitleTrack = this.mediaPlayerConfig.subtitleTrack;
            prefSubtitleTrack = subtitleTracks.find(subtitleTrackItem =>
                subtitleTrackItem.language === subtitleTrack.language);
        } else if (subtitleTracks && subtitleTracks.length > 0) {
            const channelSubtitleTrack = this.preferredChannelTrackService.getPreferredSubtitleTrack(this.channel?.id, this.settings);
            if (channelSubtitleTrack) {
                prefSubtitleTrack = subtitleTracks.find(subtitleTrack =>
                    subtitleTrack.language === channelSubtitleTrack.language);
            } else {
                const subPrefs = [ this.settings.subPrefLevel1, this.settings.subPrefLevel2, this.settings.subPrefLevel3 ];
                for (const subPref of subPrefs) {
                    if (subPref === 'disabled') {
                        prefSubtitleTrack = undefined;
                        break;
                    } else {
                        prefSubtitleTrack = subtitleTracks.find(subTrack => subTrack.language === subPref);
                        if (prefSubtitleTrack) {
                            break;
                        }
                    }
                }
            }
        }
        let promiseChain = this.activateSubtitleTrack(prefSubtitleTrack ? prefSubtitleTrack.id : undefined, false, false);
        if (prefAudioTrack) {
            promiseChain = promiseChain.then(() => this.activateAudioTrack(prefAudioTrack.id, false, false));
        }
        return promiseChain;
    }

    public getAudioTracks() {
        return this.mediaPlayerHalService.getAudioTracks();
    }

    public getSubtitleTracks() {
        return this.mediaPlayerHalService.getSubtitleTracks();
    }

    public getActiveAudioTrack() {
        return this.mediaPlayerHalService.getActiveAudioTrack();
    }

    public getActiveSubtitleTrack() {
        return this.mediaPlayerHalService.getActiveSubtitleTrack();
    }

    public getActiveBroadcastGapGroup() {
        return this.broadcastGapGroupActive;
    }

    public getChannelBroadcastGapsIntervals(channelId = this.channel?.id): BroadcastGapGroup[] {
        if (this.cursorTile && channelId) {
            const interval = {
                from: this.cursorTile.raw.start,
                to: this.cursorTile.raw.end
            };
            const startOver = this.broadcastGapsV3Service.isStartOver(this.cursorTile.raw.end);
            return this.broadcastGapsV3Service.getBroadcastGapGroupsInInterval(channelId, interval, startOver);
        }
        return [];
    }

    public broadcastGapGroupSkipAction(broadcastGapGroup: BroadcastGapGroup) {
        switch (this.mediaPlayerType) {
            case MediaPlayerTypes.CHANNEL:
                if (broadcastGapGroup.datetimeTo === null) {
                    return this.playLive(this.channel.id);
                }
                return this.playTs(this.channel.id, broadcastGapGroup.datetimeTo);
            case MediaPlayerTypes.NPVR:
                return this.playNpvr(this.cursorTile.id, broadcastGapGroup.datetimeTo, true);
            case MediaPlayerTypes.EPISODE:
                return this.playEpisode(this.cursorTile.id, broadcastGapGroup.datetimeTo, true);
        }
    }

    /* State */
    public isLive(): boolean {
        return this.mediaPlayerParams?.type === 'live';
    }

    public isTs(): boolean {
        return this.mediaPlayerParams?.type === 'ts';
    }

    public isPlayerLoaded(): boolean {
        return this.playerLoaded;
    }

    public isPaused(): boolean {
        return !!this.pausedTime;
    }

    public isStopped(): boolean {
        return this.stopped;
    }

    public isSeeking(): boolean {
        return this.seeking;
    }

    public isSleeping(): boolean {
        return this.sleeping;
    }

    public isLocked(): boolean {
        return !!this.lockedReason;
    }

    public isChromecastConnected() {
        return this.mediaPlayerHalService.chromecastConnected$?.getValue();
    }

    public isAirplayConnected() {
        return this.mediaPlayerHalService.airplayConnected$?.getValue();
    }

    public isTimeshiftDisabled() {
        return this.mediaPlayerType === MediaPlayerTypes.CHANNEL && this.channel && this.channel.timeshiftTime === 0;
    }

    public isObeySkippable() {
        return this.channel?.id &&
            // obeySkippable allowed
            this.channel.gapSettings.obeySkippable &&
            // is channel or NPVR with !npvrSkipAllowed
            (this.mediaPlayerType === MediaPlayerTypes.CHANNEL ||
                this.mediaPlayerType === MediaPlayerTypes.EPISODE ||
                (this.mediaPlayerType === MediaPlayerTypes.NPVR && !this.channel.gapSettings.npvrSkipAllowed)
            ) && !this.isStartProtectingInterval();
    }

    public isStartProtectingInterval() {
        return this.protectingStartTime &&
            (Date.now() - this.protectingStartTime) < (this.channel.gapSettings.continuePlayFreeSeconds * 1000);
    }

    public isStartOver() {
        return this.broadcastGapsV3Service.isStartOver(this.cursorTile?.raw.end);
    }

    public getStartProtectingInterval() {
        return this.channel && (this.channel.gapSettings.continuePlayFreeSeconds * 1000) - (Date.now() - this.protectingStartTime);
    }

    public getStartProtectingFreetime() {
        return this.channel && this.channel.gapSettings.continuePlayFreeSeconds * 1000;
    }

    public hasNativeBuffer() {
        return this.mediaPlayerHalService.nativeBuffer;
    }

    public getMediaPlayerConfig() {
        return this.mediaPlayerConfig;
    }

    public getMediaPlayerType() {
        return this.mediaPlayerType;
    }

    public getChannel() {
        return this.channel;
    }

    public getCursorTile() {
        return this.cursorTile;
    }

    public getWatchingTile() {
        return this.watchingTile;
    }

    public getStoredSignIdent() {
        return this.signIdent;
    }

    public getCursorTime(): number {
        return this.cursorTime;
    }

    public getLiveTime(): number {
        return this.liveTime;
    }

    public getWatchingTime(): number {
        return this.watchingTime;
    }

    public refreshUrl() {
        this.mediaPlayerParams.start = this.watchingTime;
        this.changeUrl();
        // delay to wait to change for last JS stack instructions
        return timer(0);
    }

    private getTileByStartTime(startTime: number): MediaTile {
        if (!this.tiles) {
            return;
        }
        if (this.tiles.now && startTime >= this.tiles.now.raw.start && startTime < this.tiles.now.raw.end) {
            return this.tiles.now;
        }

        if (this.tiles.past && startTime >= this.tiles.past.raw.start && startTime < this.tiles.past.raw.end) {
            return this.tiles.past;
        }

        if (this.tiles.future && startTime >= this.tiles.future.raw.start && startTime < this.tiles.future.raw.end) {
            return this.tiles.future;
        }
    }

    private getEpgLive(channel: Channel): MediaTile {
        if (!this.epgLive) {
            return;
        }
        const epgLiveTile = this.epgLive[ channel.id ];
        const now = Date.now();
        if (epgLiveTile && now >= epgLiveTile.raw.start && now < epgLiveTile.raw.end) {
            return epgLiveTile;
        }
    }

    public getPlayerDebug(): Observable<string> {
        if (this.mediaPlayerHalService.getPlayerDebug) {
            return from(this.mediaPlayerHalService.getPlayerDebug());
        }
        return of('Debug not supported');
    }

    public getMediaEnd(): number {
        if (!this.cursorTile) {
            return;
        }
        return this.getEndTimeWithOverlap(this.cursorTile);
    }

    public canPlayMcast(): boolean {
        return this.getMcast();
    }

    public getLockedReason(): MediaPlayerRestrictions {
        return this.lockedReason;
    }

    /* Restrictions */
    private isSessionRestricted() {
        const sessionPlayRestricted = this.restrictionService.isSessionRestricted();
        if (sessionPlayRestricted) {
            this.notificationService.show(this.translateService.instant('NOTIFICATIONS.GENERAL.SESSION_RESTRICTED'));
            return true;
        }
    }

    private isSessionPlayRestricted() {
        const sessionPlayRestricted = this.restrictionService.isSessionPlayRestricted();
        if (sessionPlayRestricted) {
            this.notificationService.show(this.translateService.instant('NOTIFICATIONS.MEDIA_PLAYER.SESSION_PLAY_RESTRICTED'));
            return true;
        }
    }

    private isCellPlayRestricted() {
        if (this.coreService.isMobilePlatform() &&
            !this.settings.useMobileData &&
            this.platformHalService.getConnectionType &&
            this.platformHalService.getConnectionType() === 'cell') {
            this.notificationService.show(this.translateService.instant('NOTIFICATIONS.MEDIA_PLAYER.MOBILE_CELL_RESTRICTED'));
            return true;
        }
    }

    private isChannelMissing(channel: Channel) {
        if (!channel?.ident) {
            this.notificationService.show(this.translateService.instant('NOTIFICATIONS.MEDIA_PLAYER.MISSING_CHANNEL'));
            return true;
        }
    }

    private isChannelParentalRestricted(channel: Channel) {
        const pornRestricted = this.parentalControlService.isChannelPornRestricted(channel);
        const ageRestricted = this.parentalControlService.isChannelAgeRestricted(channel);
        return pornRestricted || ageRestricted;
    }

    private isNpvrMissing(tile: MediaTile) {
        if (!tile?.ident) {
            this.notificationService.show(this.translateService.instant('NOTIFICATIONS.MEDIA_PLAYER.NPVR_NOT_FOUND'));
            return true;
        }
    }

    private isNpvrParentalRestricted(tile: MediaTile) {
        const pornRestricted = this.tileService.isPornRestricted(tile);
        const ageRestricted = this.tileService.isAgeRestricted(tile);
        return pornRestricted || ageRestricted;
    }

    private isEpisodeMissing(tile: MediaTile) {
        if (!tile) {
            this.notificationService.show(this.translateService.instant('NOTIFICATIONS.MEDIA_PLAYER.EPISODE_NOT_FOUND'));
            return true;
        }
    }

    private isEpisodePlayableRestricted(tile: MediaTile) {
        if (!(tile as EpisodeTile).playable) {
            this.notificationService.show((tile as EpisodeTile).notPlayableReason);
            return true;
        }
    }

    private isEpisodeParentalRestricted(tile: MediaTile) {
        const pornRestricted = this.tileService.isPornRestricted(tile);
        const ageRestricted = this.tileService.isAgeRestricted(tile);
        return pornRestricted || ageRestricted;
    }

    private isVodMissing(tile: MediaTile) {
        if (!tile?.ident) {
            this.notificationService.show(this.translateService.instant('NOTIFICATIONS.MEDIA_PLAYER.VOD_NOT_FOUND'));
            return true;
        }
    }

    private isVodBoughtRestricted(tile: MediaTile) {
        if (!this.tileService.isVodBought(tile as VodTile)) {
            this.notificationService.show(this.translateService.instant('NOTIFICATIONS.MEDIA_PLAYER.VOD_NOT_BOUGHT'));
            return true;
        }
    }

    private isVodParentalRestricted(tile: MediaTile) {
        const pornRestricted = this.tileService.isPornRestricted(tile);
        const ageRestricted = this.tileService.isAgeRestricted(tile);
        return pornRestricted || ageRestricted;
    }

    private isTsPlayRestricted(channel: Channel, startTime: number) {
        if (this.restrictionService.isChannelTsRestricted(channel, startTime)) {
            this.notificationService.show(this.translateService.instant('NOTIFICATIONS.MEDIA_PLAYER.TS_RESTRICTED'));
            return true;
        }
    }

    private isBroadcastRestricted(channel: Channel, startTime: number) {
        return this.restrictionService.isChannelBroadcastRestricted(channel, startTime);
    }

    /* HTTP Api */
    private fetchEpgLive() {
        return this.httpClient.get<{ [ key: string ]: MediaTile }>(environment.apiUrl + 'epg-live')
            .pipe(
                tap((epgLive) => {
                    this.epgLive = epgLive;
                }));
    }

    private fetchMediaPlayerTiles(channel: Channel, startTime: number = Date.now()): Observable<EpgEntityAtTime> {
        if (!channel) {
            return of(undefined);
        }
        return this.channelService.getEpgEntityAtTime(channel.id, startTime)
            .pipe(
                tap((tiles) => {
                    this.tiles = tiles;
                }));
    }

    private fetchContinuingEntity(tile: MediaTile) {
        if (!tile) {
            return throwError(MediaPlayerErrors.MISSING_CONTINUING_ENTITY);
        }
        switch (tile.mediaType) {
            case MediaTypes.EPG_ENTITY:
                return this.httpClient.get<MediaTile>(`${ environment.apiUrl }epg-entity/${ tile.id }/continue`);
            case MediaTypes.EPISODE:
                return this.httpClient.get<MediaTile>(`${ environment.apiUrl }episode/${ tile.id }/continue`);
        }
    }

    private signRequest(data: { ident: string, type: string }): Observable<Cert> {
        return this.httpClient
            .post<Cert>(environment.apiUrl + 'sign/video', data);
    }

    /* Params getters */
    private getMaxBitrate(bitrate: number, maxBitrateLimit: number) {
        if (maxBitrateLimit > 0) {
            return (bitrate === 0) ? maxBitrateLimit : Math.min(maxBitrateLimit, bitrate);
        } else {
            return bitrate;
        }
    }

    // get min value from 'max' values
    private getMaxLsps(platformMaxLumaPerSec: number, portalMaxLumaPerSec: number) {
        platformMaxLumaPerSec = platformMaxLumaPerSec || 0;
        portalMaxLumaPerSec = portalMaxLumaPerSec || 0;
        if (portalMaxLumaPerSec > 0 && (platformMaxLumaPerSec <= 0 || portalMaxLumaPerSec < platformMaxLumaPerSec)) {
            return portalMaxLumaPerSec;
        }
        return platformMaxLumaPerSec || undefined;
    }

    public getWV(): boolean {
        const getDataVw = () => {
            switch (this.mediaPlayerType) {
                case MediaPlayerTypes.CHANNEL:
                case MediaPlayerTypes.EPISODE:
                    return this.channel.wv;
                case MediaPlayerTypes.NPVR:
                case MediaPlayerTypes.VOD:
                    return this.cursorTile.wv;
            }
        };
        return this.platformHalService.getFeatures().wv && getDataVw();
    }

    public getHevc(): boolean {
        if (!this.platformHalService.getFeatures().hevc || !this.mediaPlayerHalService.hevc) {
            return false;
        }
        switch (this.mediaPlayerType) {
            case MediaPlayerTypes.CHANNEL:
            case MediaPlayerTypes.EPISODE:
                return this.channel.hevc;
            case MediaPlayerTypes.NPVR:
            case MediaPlayerTypes.VOD:
                return this.cursorTile.hevc;
        }
    }

    private getMcast(channel: Channel = this.channel): boolean {
        const maxLspsCap = this.platformHalService.getFeatures()[ 'maxLumaSamplesPerSec' ];
        return this.portalSettings.mediaPlayer.mcast &&
            hal.cap.mcast &&
            channel.source &&
            channel.source.length > 0 &&
            (maxLspsCap < 1 || maxLspsCap >= channel.mcastLsps);
    }

    private getSignIdent(): string {
        switch (this.mediaPlayerType) {
            case MediaPlayerTypes.CHANNEL:
            case MediaPlayerTypes.EPISODE:
                return this.channel.ident;
            case MediaPlayerTypes.NPVR:
            case MediaPlayerTypes.VOD:
                return this.cursorTile.ident;
        }
    }

    /* Watchers */
    private initPlayerTimer() {
        if (!this.playerTimer$) {
            this.playerTimer$ = this.onPlayerTimerRefresh
                .pipe(
                    tap(() => {
                        this.liveTime = Date.now();
                    }),
                    switchMap(() => timer(0, this.TIMER_INTERVAL)),
                    tap(() => {
                        const time = Date.now();
                        const delta = time - this.liveTime;
                        this.liveTime = time;
                        if (!this.timersStuck.cursor) {
                            this.cursorTime += delta;
                        }
                        if (!this.timersStuck.watched) {
                            this.watchingTime += delta;
                        }
                        this.checkTime();
                        this.storeWatchingTile();
                        if (this.lastLogPrevented) {
                            this.loggingService.repeat();
                        }
                        // console.log('cursorTime', new Date(this.cursorTime));
                        // console.log('watchingTime', new Date(this.watchingTime));
                    }),
                    publishReplay(1),
                    refCount()
                ) as ConnectableObservable<number>;
        }

        // Subscribe to ensure living timers through complete live of player
        SOM.clearSubscriptions(this.subscription.playerTimer);
        this.subscription.playerTimer = this.playerTimer$.subscribe();
    }

    private initSeeking() {
        SOM.clearSubscriptions(this.subscription.onSeeking);
        this.ngZone.runOutsideAngular(() => {
            this.subscription.onSeeking = this.onSeeking
                .pipe(
                    debounceTime(500),
                )
                .subscribe((seek) => {
                    this.seeking = false;
                    SOM.clearSubscriptions(this.subscription.seekToPos);
                    this.subscription.seekToPos = this.seekToPosition(seek.startTime, seek.direction).subscribe();
                });
        });
    }

    private initBroadcastChecking(channel: Channel) {
        SOM.clearSubscriptions(this.subscription.broadcastChecker);
        this.ngZone.runOutsideAngular(() => {
            this.subscription.broadcastChecker = timer(5000, 5000).subscribe(() => {
                SOM.clearSubscriptions(this.subscription.broadcastRefreshPlayer);
                if (!this.isLocked()) {
                    if (this.isBroadcastRestricted(channel, this.watchingTime)) {
                        this.lockPlayer(MediaPlayerRestrictions.BROADCAST);
                    }
                } else if (this.lockedReason === MediaPlayerRestrictions.BROADCAST) {
                    if (!this.isBroadcastRestricted(channel, this.watchingTime)) {
                        this.subscription.broadcastRefreshPlayer = this.refreshPlayer().subscribe();
                    }
                }
            });
        });
    }

    private initBroadcastGapFetching() {
        if (!this.isAdsEnabled()) {
            return;
        }
        SOM.clearSubscriptions(this.subscription.gapsFetching);
        this.ngZone.runOutsideAngular(() => {
            this.subscription.gapsFetching =
                timer(0, 60000).pipe(
                    switchMap(() => this.broadcastGapsV3Service.fetchBroadcastGaps(this.channel.id, this.watchingTime))
                ).subscribe(() => {
                    this.onBroadcastGapUpdated.next();
                });
        });
        SOM.clearSubscriptions(this.subscription.gapsAdded);
        this.subscription.gapsAdded  = this.broadcastGapsV3Service.broadcastGapAdded$.subscribe(() => {
            this.onBroadcastGapUpdated.next();
        });
    }

    private initBroadcastGapsChecking() {
        if (!this.isAdsEnabled()) {
            return;
        }
        SOM.clearSubscriptions(this.subscription.gapsWatching);
        this.ngZone.runOutsideAngular(() => {
            this.subscription.gapsWatching = timer(0, 1000)
                .pipe(
                    map(() => {
                        if (this.cursorTile) {
                            const startOver = this.broadcastGapsV3Service.isStartOver(this.cursorTile.raw.end);
                            return this.broadcastGapsV3Service.getBroadcastGroupAtTime(this.channel.id, this.watchingTime, startOver);
                        }
                    })
                ).subscribe((broadcastGapGroupActive) => {
                    if (this.isPaused()) {
                        return;
                    }
                    const tempActive = this.broadcastGapGroupActive ? { ...this.broadcastGapGroupActive } : undefined;
                    this.broadcastGapGroupActive = broadcastGapGroupActive;
                    if ((!tempActive || tempActive.skippable) &&
                        (this.broadcastGapGroupActive && !this.broadcastGapGroupActive.skippable)) {
                        if (!environment.public) {
                            this.notificationService.show('AD START', { duration: 1000 });
                        }
                        this.onBroadcastGapChanged.next();
                    } else if ((tempActive && !tempActive.skippable) &&
                        (!this.broadcastGapGroupActive || this.broadcastGapGroupActive.skippable)) {
                        if (!environment.public) {
                            this.notificationService.show('AD END', { duration: 1000 });
                        }
                        this.onBroadcastGapChanged.next();
                    } else if (tempActive?.id !== this.broadcastGapGroupActive?.id) {
                        this.onBroadcastGapChanged.next();
                    }
                });
        });
    }

    private initEpgLiveFetching() {
        if (this.epgLiveSubscription) {
            return;
        }
        this.ngZone.runOutsideAngular(() => {
            this.epgLiveSubscription = timer(0, this.EPG_LIVE_FETCHING_INTERVAL)
                .pipe(
                    switchMap(() => this.fetchEpgLive())
                )
                .subscribe(epgLive => {
                    this.epgLive = epgLive;
                });
        });
    }

    private initLayoutSwitchWatching() {
        SOM.clearSubscriptions(this.subscription.switchLayout);
        if (!this.mediaPlayerHalService.switchLayout$) {
            return;
        }
        let audioTrack: AudioTrack;
        this.subscription.switchLayout = this.mediaPlayerHalService.switchLayout$
            .pipe(
                switchMap((switchedAudioTrack) => {
                    audioTrack = switchedAudioTrack;
                    if (!this.isLive()) {
                        this.mediaPlayerParams.start = this.watchingTime;
                    }
                    return this.play();
                }),
                switchMap(() => this.tracksUpdated$
                    .pipe(
                        filter(loaded => loaded),
                        take(1)
                    )
                )
            )
            .subscribe(() => {
                if (audioTrack) {
                    this.mediaPlayerHalService.activateAudioTrackByLanguageAndLayout(audioTrack.language, audioTrack.layout);
                }
            });
    }

    private initPublishWatching() {
        SOM.clearSubscriptions(this.subscription.publishWatchingInterval);
        this.ngZone.runOutsideAngular(() => {
            this.subscription.publishWatchingInterval = timer(this.PUBLISH_WATCHING_INTERVAL, this.PUBLISH_WATCHING_INTERVAL)
                .pipe(
                    switchMap(() => this.publishWatching())
                )
                .subscribe();
        });
    }

    private initAirplayWatch() {
        SOM.clearSubscriptions(this.subscription.requestAirplayUrl);
        if (this.mediaPlayerHalService.requestAirplayUrl$) {
            this.subscription.requestAirplayUrl = this.mediaPlayerHalService.requestAirplayUrl$.subscribe(() => {
                if (!this.cert) {
                    return;
                }
                this.mediaPlayerParams = {
                    ...this.mediaPlayerParams,
                    start: this.watchingTime,
                    dialect: '1es',
                    fmt: 'mp4',
                    wv: 0,
                    dd: 1,
                    dt: 'airplay'
                };
                const url = this.generateStreamUrl(this.mediaPlayerParams, this.cert);
                this.mediaPlayerHalService.playAirplay(url);
            });
        }
    }

    private initEndWatch() {
        if (!this.mediaPlayerHalService.end$) {
            return;
        }
        SOM.clearSubscriptions(this.subscription.endWatch);
        this.subscription.endWatch = this.mediaPlayerHalService.end$
            .subscribe(() => {
                console.log('Stream ends. Player stop.');
                SOM.clearSubscriptions(this.subscription.end);
                this.subscription.end = this.stop().subscribe();
            });
    }

    private initErrorsWatch() {
        if (!this.mediaPlayerHalService.error$) {
            return;
        }
        SOM.clearSubscriptions(this.subscription.errorWatch);
        this.subscription.errorWatch = this.mediaPlayerHalService.error$
            .subscribe((error) => {
                SOM.clearSubscriptions(this.subscription.error);
                this.subscription.error = this.error(error).subscribe();
            });
    }

    public getLogPlaybackObject(): Playback {
        if (!this.watchingTile) {
            return;
        }
        const logPlayback = this.generateLogPlayback(this.watchingTile);
        if (logPlayback) {
            return {
                time: this.tileService.hasAbsoluteWatchedTime(this.watchingTile.mediaType) ?
                    this.watchingTime : this.watchingTime - this.watchingTile.raw.start,
                hevc: this.getHevc(),
                ...logPlayback,
                ...this.generateTracksPlayback()
            } as Playback;
        }
    }

    private generateLogPlayback(watchingTile: MediaTile): Partial<Playback> {
        switch (watchingTile.mediaType) {
            case MediaTypes.EPG_ENTITY:
                if (!this.channel) {
                    return;
                }
                return {
                    type: this.isLive() ? 'live' : 'timeshift',
                    entity: {
                        epg: {
                            id: watchingTile.id,
                            channel: {
                                id: this.channel.id,
                                name: this.channel.name
                            }
                        }
                    }
                };
            case MediaTypes.NPVR:
                if (!this.channel) {
                    return;
                }
                return {
                    type: 'npvr',
                    entity: {
                        npvr: {
                            id: watchingTile.id,
                            title: watchingTile.label
                        },
                        epg: {
                            channel: {
                                id: this.channel.id,
                                name: this.channel.name
                            },
                            id: watchingTile.epgEntityId,
                        }
                    }
                };
            case MediaTypes.EPISODE:
                return {
                    type: 'serial',
                    entity: {
                        episode: {
                            id: watchingTile.id,
                            title: watchingTile.label
                        }
                    }
                };
            case MediaTypes.VOD:
                return {
                    type: 'vod',
                    entity: {
                        vod: {
                            id: watchingTile.id,
                            title: watchingTile.label
                        }
                    }
                };
        }
    }

    private generateTracksPlayback() {
        const activeAudioTrack = this.getActiveAudioTrack();
        const activeSubtitleTrack = this.getActiveSubtitleTrack();
        return {
            audioTracks: this.getAudioTracks().map((track) => ({
                label: track.label,
                language: track.language,
                layout: track.layout
            })),
            subtitleTracks: this.getSubtitleTracks().map((track) => ({
                label: track.label,
                language: track.label
            })),
            activeAudioTrack: {
                language: activeAudioTrack?.language,
                layout: activeAudioTrack?.layout
            },
            activeSubtitleTrack: {
                language: activeSubtitleTrack?.language
            }
        };
    }

    private logWithPlayback(event: LogEvents = this.loggingService.getLogState()?.event,
                            options: LogOptions = { requirePlayback: true }) {
        if (this.mediaPlayerConfig?.onlyPlay) {
            return;
        }
        if (!this.loggingService.log(event, options)) {
            this.lastLogPrevented = true;
        }
    }

    initProtectionStartTime() {
        if (!this.channel) {
            return;
        }
        // const storedData = localStorage.getItem('protectingStartTime');
        // const storedTimeObject = storedData ? JSON.parse(storedData) : {};
        this.onTileChanged.pipe(take(1)).subscribe(() => {
            if (!this.broadcastGapsV3Service.isStartOver(this.cursorTile.raw.end)) {
                // if (storedTimeObject[ this.channel.id ]) {
                //     if (Date.now() - storedTimeObject[ this.channel.id ] < this.PROTECTION_SKIPPABLE_CACHE_LIMIT) {
                //         this.protectingStartTime = storedTimeObject[ this.channel.id ];
                //         return;
                //     }
                // } else {
                    this.protectingStartTime = Date.now();
                    // storedTimeObject[ this.channel.id ] = this.protectingStartTime;
                    // localStorage.setItem('protectingStartTime', JSON.stringify(storedTimeObject));
                // }
            }
        });
    }

    public isAdsEnabled() {
        return this.portalSettings?.ads.enabled && this.channel?.gapSettings.enabled;
    }
}
