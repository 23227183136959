import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { NotificationService } from '@kuki/global/shared/modules/notification/notification.service';
import { Errors } from '@kuki/global/shared/types/enum/errors';
import { hal } from '@kuki/platforms/hal';
import { PlatformHal } from '@kuki/platforms/platform-hal';
import { TranslateService } from '@ngx-translate/core';
import { from, Observable, throwError, timer } from 'rxjs';
import { catchError, mergeMap, retryWhen, switchMap } from 'rxjs/operators';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

    private RETRY_TIMEOUT = 1000;
    private INVALID_SESSION_TIMEOUT = 3000;

    constructor(
        @Inject('PlatformHalService') private platformHalService: PlatformHal,
        private notificationService: NotificationService,
        private translateService: TranslateService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next
            .handle(request)
            .pipe(
                retryWhen((errors: Observable<HttpErrorResponse>) => errors.pipe(
                    mergeMap((error, i) => {
                        // 2 retries
                        if (i >= 2 || (!(error?.status?.toString().startsWith('50')) && error?.status !== 0)) {
                            return throwError(error);
                        }
                        return timer(this.RETRY_TIMEOUT);
                    }))),
                catchError(error => {
                    if (error && error.error) {
                        const errorObject = error.error;
                        if (errorObject) {
                            if (errorObject.detail && errorObject.not_allowed_reason) {
                                this.notificationService.show(errorObject.detail, errorObject.not_allowed_reason);
                                return throwError(error);
                            } else if (errorObject.detail) {
                                this.notificationService.show(errorObject.detail);
                                if (errorObject.error === Errors.INVALID_SESSION_KEY) {
                                    return timer(this.INVALID_SESSION_TIMEOUT).pipe(
                                        switchMap(() => {
                                            this.platformHalService.restart();
                                            return throwError(error);
                                        })
                                    );
                                } else {
                                    return throwError(error);
                                }
                            }
                        }
                    }
                    if (error.status === 500) {
                        this.notificationService.show(this.translateService.instant('NOTIFICATIONS.GENERAL.APPLICATION_ERROR'));
                    } else if (error.status === 0) {

                        const url = new URL(request.url);

                        if (hal.cap.connectionCheck && this.platformHalService?.checkConnection) {
                            return from(Promise.resolve(this.platformHalService?.checkConnection()))
                                .pipe(switchMap((connection) => {
                                    if (connection === 'ok') {
                                        this.notificationService.show(this.translateService.instant('NOTIFICATIONS.GENERAL.HTTP_ERROR')+url.hostname.split('.')[0]);
                                    }
                                    return throwError(error);
                                }));
                        } else {
                            this.notificationService.show(this.translateService.instant('NOTIFICATIONS.GENERAL.HTTP_ERROR')+url.hostname.split('.')[0]+'(connection)');
                        }
                    } else {
                        this.notificationService.show(this.translateService.instant('NOTIFICATIONS.GENERAL.UNKNOWN_ERROR'));
                    }
                    return throwError(error);
                })
            );
    }
}
