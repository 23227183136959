<div class="modal-header">
    <div class="modal-title">{{ 'MODAL.PASSWORD.TITLE' | translate }}</div>
    <div class="modal-close" (click)="onDismissModal()">
        <span class="icon icon-times"></span>
    </div>
</div>
<div class="modal-body" #modalBody>
    <div class="password-modal-image" *appPlatform="'WEB'"></div>
    <form class="form" [formGroup]="passwordForm" (ngSubmit)="onSubmit()">
        <div class="form-group">
            <input type="password"
                   name="password"
                   id="password"
                   class="form-control"
                   formControlName="password"
                   placeholder="{{ data?.placeholder }}"
                   tabindex="1"
                   #input>
        </div>
        <div class="buttons-panel">
            <button class="button" tabindex="2">{{ 'MODAL.PASSWORD.SEND' | translate }}</button>
        </div>
    </form>
</div>
