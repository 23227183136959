import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RemoteControlComponent } from './remote-control.component';
import { OsdModule } from '@kuki/global/features/osd/osd.module';
import { ButtonsPanelModule } from '@kuki/global/features/buttons-panel/buttons-panel.module';
import { SourceLogoModule } from '@kuki/global/features/source-logo/source-logo.module';
import { LabelsPanelModule } from '@kuki/global/features/labels-panel/labels-panel.module';
import { AutoScrollContainerModule } from '@kuki/global/shared/modules/auto-scroll-container/auto-scroll-container.module';
import { TranslateModule } from '@ngx-translate/core';
import { DirectivesModule } from '@kuki/global/shared/directives/directives.module';
import { RemoteControlService } from '@kuki/web/features/remote-control/remote-control.service';
import { PipesModule } from '@kuki/global/shared/pipes/pipes.module';
import { ArrowBackModule } from '@kuki/web/features/arrow-back/arrow-back.module';
import { RemoteControlPlayerComponent } from './remote-control-player/remote-control-player.component';
import { RemoteControlGlobalComponent } from './remote-control-global/remote-control-global.component';
import { ImageLinkFactoryModule } from '@kuki/global/shared/modules/image-link-factory/image-link-factory.module';


@NgModule({
    declarations: [ RemoteControlComponent, RemoteControlPlayerComponent, RemoteControlGlobalComponent ],
    exports: [
        RemoteControlComponent
    ],
    imports: [
        CommonModule,
        OsdModule,
        ButtonsPanelModule,
        SourceLogoModule,
        LabelsPanelModule,
        AutoScrollContainerModule,
        TranslateModule,
        DirectivesModule,
        PipesModule,
        ArrowBackModule,
        ImageLinkFactoryModule
    ]
})
export class RemoteControlModule {
    static forRoot(): ModuleWithProviders<RemoteControlModule> {
        return {
            ngModule: RemoteControlModule,
            providers: [
                RemoteControlService
            ]
        };
    }
}
