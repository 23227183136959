import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { SearchService } from '@kuki/global/shared/services/search.service';
import { Router } from '@angular/router';
import { NavigationService } from '@kuki/global/shared/services/navigation.service';
import { SOM, SubscriptionObject } from '@kuki/global/shared/others/subscription/subscription-object';
import { debounceTime } from 'rxjs/operators';
import { ControllerService } from '@kuki/global/shared/services/controller.service';
import { WebKeys } from '@kuki/global/shared/types/controller/keymap';
import { CoreService } from '@kuki/global/shared/services/core.service';

@Component({
    selector: 'app-search-form',
    templateUrl: './search-form.component.html'
})
export class SearchFormComponent implements OnInit, OnDestroy {

    private ident: string = 'search-form';
    public query: string;
    public filter: { [ key: string ]: Array<string> };
    private subscription: SubscriptionObject = {};

    @ViewChild('searchInput') searchInput: ElementRef<HTMLInputElement>;

    constructor(
        private coreService: CoreService,
        private searchService: SearchService,
        private navigationService: NavigationService,
        private controllerService: ControllerService,
        private changeDetectorRef: ChangeDetectorRef,
        private router: Router) {
    }

    ngOnInit() {
        this.subscription.onQueryChanged = this.searchService.queryChanged.pipe(debounceTime(0)).subscribe((query) => {
            this.query = query;
        });
        this.subscription.onFilterChanged = this.searchService.filterChanged.subscribe(() => {
            this.filter = this.searchService.filter;
        });
        this.controllerService.registerActionKeys([ WebKeys.SLASH ], this.ident, (action) => {
            if (action.event) {
                action.event.preventDefault();
            }
            // ignore if already focused to enable / symbol be used in input field
            if (document.activeElement === this.searchInput.nativeElement) {
                return;
            }
            // timeout to ignore slash symbol be written inside input field
            setTimeout(() => {
                this.searchInput.nativeElement.focus();
            });
        });
    }

    public onSearch(query) {
        this.searchService.query = query;
        this.searchService.search();
    }

    public onShowSearch() {
        this.router.navigate([ '/search' ]);
    }

    public onClickSearchIcon() {
        if (this.coreService.isMobilePlatform()) {
            this.searchService.toggleFilter();
        }
        this.onShowSearch();
    }

    public hasFilter() {
        return this.filter && Object.keys(this.filter).length > 0;
    }

    public getSearchOpenIcon() {
        const hasFilter = this.hasFilter();
        return {
            'icon-search-open': !hasFilter,
            'icon-search-open-pink': hasFilter
        };
    }


    ngOnDestroy() {
        this.controllerService.unregisterStackLevel(this.ident);
        SOM.clearSubscriptionsObject(this.subscription);
    }
}
