<div class="modal-header">
    <div class="modal-title">{{ data?.message }}</div>
    <div class="modal-close" (click)="onDismissModal()">
        <span class="icon icon-times"></span>
    </div>
</div>
<div class="modal-body" #modalBody>
    <div class="action-item" *ngFor="let item of actionButtons" (click)="onAction(item)">
        <div *ngIf="item.icon" class="icon {{item.icon}}"></div>
        {{ item.label }}
    </div>
    <div *ngIf="loaded && (!actionButtons || actionButtons.length === 0)" class="actions-modal-empty">{{ data.emptyMessage }}</div>
</div>

