<footer>
    <div class="container">
        <div class="footer-content">
            <div>
                <a [routerLink]="['/']"><span class="icon icon-logo"></span></a>
            </div>
            <div class="footer-contact" [innerHTML]="customerCare" *ngIf="customerCare">
            </div>
        </div>
        <div class="footer-copyrights">
            <p [innerHTML]="'WEB.FOOTER.TEXT' | translate: { actualYear: actualYear }"></p>
            <small [innerHTML]="'WEB.FOOTER.COOKIES' | translate"></small>
        </div>
    </div>
</footer>
