<div class="active-profile" (click)="toggleProfilePicker()">
    <app-profile-icon *ngIf="getActiveProfile(); else defaultProfile; let activeProfile"
                      [profile]="activeProfile"></app-profile-icon>
    <ng-template #defaultProfile>
        <div class="profile-icon profile-icon-default"></div>
    </ng-template>
</div>

<div *ngIf="profilePickerVisible" class="profile-picker">
    <div *ngFor="let profile of profiles"
         class="profile-picker-item"
         [ngClass]="getProfilePickerClass(profile)"
         (click)="onSelectProfile(profile)">
        <app-profile-icon [profile]="profile"></app-profile-icon>
        <div class="profile-picker-text">{{ profile.label | truncate: 7 }}</div>
        <div class="profile-picker-actions">
            <span (click)="onToggleProfile(profile, $event)"
                  [class]="isActiveProfile(profile) ? 'icon-check-circle-fill-dark' : 'icon-check-circle-dark'"></span>
            <ng-container *appProfileType="['classic', 'senior']">
                <span (click)="onEditProfile(profile, $event)" class="icon icon-pen-dark"></span>
                <span (click)="onDeleteProfile(profile, $event)" class="icon icon-trash-dark"></span>
            </ng-container>
        </div>
    </div>
    <div *appProfileType="[ 'classic', 'senior' ]"
         class="profile-picker-item profile-picker-item-logout"
         (click)="onLogout()">
        <div class="profile-picker-text">{{ 'GENERAL.ACTION.LOGOUT' | translate }}</div>
    </div>
</div>
