import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from '@kuki/web/features/header/header.component';
import { VisibleModule } from '@kuki/global/shared/modules/visible/visible.module';
import { ArrowBackModule } from '@kuki/web/features/arrow-back/arrow-back.module';
import { RouterModule } from '@angular/router';
import { SearchFormModule } from '@kuki/web/features/search-form/search-form.module';
import { ProfilePickerModule } from '@kuki/web/features/profile-picker/profile-picker.module';
import { NpvrStatsModule } from '@kuki/global/features/npvr-stats/npvr-stats.module';
import { TranslateModule } from '@ngx-translate/core';
import { DirectivesModule } from '@kuki/global/shared/directives/directives.module';

@NgModule({
    imports: [
        CommonModule,
        VisibleModule,
        ArrowBackModule,
        SearchFormModule,
        RouterModule,
        ProfilePickerModule,
        NpvrStatsModule,
        TranslateModule,
        DirectivesModule
    ],
    declarations: [ HeaderComponent ],
    exports: [
        HeaderComponent
    ]
})
export class HeaderModule {
}
