<div class="header-wrapper">
    <header>
        <div class="container">
            <app-arrow-back *appVisible="'arrow-back'"></app-arrow-back>
            <div class="row">
                <a class="logotype col-1" [routerLink]="['/']">
                    <span class="icon icon-logo"></span>
                </a>
                <nav *appProfileType="['classic', 'senior']" class="col-2">
                    <a routerLink="/settings">{{ 'WEB.HEADER.SETTINGS' | translate }}</a>
                    <a routerLink="/shop">{{ 'WEB.HEADER.SHOP' | translate }}</a>
                </nav>
                <ng-container *appProfileType="['classic', 'senior']">
                    <app-search-form *appVisible="'search'" [ngClass]="getSearchFormClass()"></app-search-form>
                </ng-container>
                <div class="ml-auto header-right">
                    <app-npvr-stats *appVisible="'npvr-stats'" (click)="onClickNpvr()"></app-npvr-stats>
                    <app-profile-picker *appVisible="'profile'"
                                        class="ml-auto"
                                        [ngClass]="{'staging-label': !publicVersion }"></app-profile-picker>
                    <span *appVisible="'cancel'"
                          class="cancel"
                          (click)="onBack()">{{ 'WEB.HEADER.CANCEL' | translate }}</span>
                </div>
            </div>
        </div>
    </header>
</div>
