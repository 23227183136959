import { ApplicationRef, ComponentFactoryResolver, Injectable, ViewContainerRef } from '@angular/core';
import { ContextMenuItem } from '@kuki/global/shared/types/general/context-menu-item';
import { ContextMenuComponent } from '@kuki/web/features/context-menu/context-menu.component';
import { GeneralService } from '@kuki/global/shared/services/general.service';
import { fromEvent, merge, Observable } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';

@Injectable()
export class ContextMenuService {

    private viewContainerRef: ViewContainerRef;
    public onClick$: Observable<ContextMenuItem>;

    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        private generalService: GeneralService,
        private applicationRef: ApplicationRef) {
    }

    public openContextPanel(items: Array<ContextMenuItem>, srcElement?: HTMLElement) {
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(ContextMenuComponent);
        this.viewContainerRef.clear();
        const componentRef = this.viewContainerRef.createComponent(componentFactory);
        (<ContextMenuComponent>componentRef.instance).items = items;
        this.onClick$ = (<ContextMenuComponent>componentRef.instance).onClick$;
        if (srcElement) {
            const rect = srcElement.getBoundingClientRect();
            const scrollTop = this.generalService.getScrollTop();
            componentRef.location.nativeElement.style.left = rect.x + 'px';
            componentRef.location.nativeElement.style.top = rect.y + 30 + scrollTop + 'px';
        }

        return merge(
            fromEvent(document, 'click')
                .pipe(
                    take(1),
                    tap((event: Event) => {
                        if (!componentRef.location.nativeElement.contains(event.target)) {
                            this.close();
                        }
                    }),
                    map(() => undefined)),
            (<ContextMenuComponent>componentRef.instance).onClick$
                .pipe(
                    take(1),
                    tap(() => {
                        this.close();
                    })
                )
        );
    }

    public setViewContainerRef(viewContainerRef: ViewContainerRef) {
        this.viewContainerRef = viewContainerRef;
    }

    public close() {
        this.viewContainerRef.clear();
    }
}
