<div class="modal-header">
    <div class="modal-title">{{ data?.message }} <ng-container *ngIf="timeoutTimer">({{ timeoutTimer }})</ng-container></div>
    <div class="modal-close" (click)="onDismissModal()">
        <span class="icon icon-times"></span>
    </div>
</div>
<div class="modal-body" #modalBody>
    <div class="buttons-panel">
        <button #yes
                class="button"
                (click)="onCloseModal(true)">{{ 'MODAL.CONFIRM.DEFAULT_BTN_1' | translate }}</button>
        <button #no
                class="button"
                (click)="onCloseModal(false)">{{ 'MODAL.CONFIRM.DEFAULT_BTN_2' | translate }}</button>
    </div>
</div>
