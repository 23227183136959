import { Injectable } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { PasswordModalComponent } from '@kuki/web/modals/password-modal/password-modal.component';
import { ConfirmModalComponent } from '@kuki/web/modals/confirm-modal/confirm-modal.component';
import { ScrollService } from '@kuki/global/shared/services/scroll.service';
import { TranslateService } from '@ngx-translate/core';
import { MediaTile } from '@kuki/global/shared/types/tile';
import { Actions } from '@kuki/global/shared/types/enum';
import { ActionsModalComponent } from '@kuki/web/modals/actions-modal/actions-modal.component';
import { Errors } from '@kuki/global/shared/types/enum/errors';
import { ActionButton } from '@kuki/global/shared/types/button';
import { Observable } from 'rxjs';
import { ActionsModalV2Component } from '@kuki/web/modals/actions-modal-v2/actions-modal-v2.component';

@Injectable()
export class ModalsService implements ModalsService {
    private modalRef: NgbModalRef;

    constructor(
        private ngbModal: NgbModal,
        private translateService: TranslateService,
        private scrollService: ScrollService) {
    }

    public openPasswordModal(data?: any): Promise<string> {
        return this.openModal(PasswordModalComponent, data, 'password-modal');
    }

    public openConfirmModal(data?: any): Promise<boolean> {
        return this.openModal(ConfirmModalComponent, data, 'confirm-modal');
    }

    public openActionsModal(data?: { type?: string, tile?: MediaTile }): Promise<Actions> {
        return this.openModal(ActionsModalComponent, data, 'actions-modal actions-modal-' + data?.type);
    }

    public openActionsModalV2(data?: {
        buttons: Array<ActionButton>,
        onAction: (actionButton: ActionButton) => Observable<any>
    }): Promise<ActionButton> {
        const buttonsCss = Math.min(data.buttons.length, 5);
        return this.openModal(ActionsModalV2Component, data, 'actions-modal actions-modal-' + buttonsCss);
    }

    public openPinModal(data?: any): Promise<string> {
        data = data || {};
        data.placeholder = data.placeholder || data.message || this.translateService.instant('MODAL.PIN.PLACEHOLDER');
        return this.openPasswordModal(data);
    }

    private openModal(modalComponent: any, data: any, windowClass?: string) {
        if (this.modalRef) {
            this.modalRef.dismiss();
        }
        this.modalRef = this.ngbModal.open(modalComponent, { windowClass: windowClass });
        this.modalRef.componentInstance.data = data;
        if (this.modalRef.componentInstance.modalBodyEl) {
            this.scrollService.disableScroll(this.modalRef.componentInstance.modalBodyEl);
        }
        return this.modalRef.result
            .catch(() => {
                throw new Error(Errors.MODAL_CLOSE);
            })
            .finally(() => {
                this.modalRef = undefined;
                this.scrollService.enableScroll();
            });
    }
}
