import { Injectable } from '@angular/core';
import { BootModeTypes } from '@kuki/global/shared/types/enum';
import { HalFeature } from '@kuki/global/shared/types/general';
import { PlatformHal } from '@kuki/platforms/platform-hal';
import { Observable, of } from 'rxjs';

@Injectable()
export class BrowserPlatformHalService implements PlatformHal {
    private readonly serialKey = 'kuki-sn';
    private features: HalFeature = {
        hevc: false,
        wv: true
    };
    public deviceReady$: Observable<void> = of(null);
    private connection: string = 'ok';

    public init() {
    }

    public getProductName(): string {
        return 'WEBBROWSER';
    }

    public getMac(): string {
        return '00:00:00:00:00:00';
    }

    public getVersionFw(): string {
        return '1.0';
    }

    public getBootMode(): BootModeTypes {
        return BootModeTypes.UNKNOWN;
    }

    public getSerial(): string {
        let serial = localStorage.getItem(this.serialKey);
        if (!serial) {
            serial = this.generateSerial();
        }
        return serial;
    }

    public getDeviceModel(): string {
        return window.navigator.userAgent.substring(0, 250);
    }

    public getClaimedDeviceId(): string {
        return this.getSerial();
    }

    public getFeatures(): HalFeature {
        return this.features;
    }

    private generateSerial(): string {
        const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let serial = 'kuki2.0_';
        for (let i = 0; i < 56; i++) {
            serial += possible.charAt(Math.floor(Math.random() * possible.length));
        }
        localStorage.setItem(this.serialKey, serial);
        return serial;
    }

    public storeValue(key: string, value: string) {
        localStorage.setItem(key, value);
    }

    public loadValue(key: string) {
        return localStorage.getItem(key);
    }

    public deleteValue(key: string) {
        localStorage.removeItem(key);
    }

    public storeSettingsValue(key: string, value: string) {
        const settingsString = localStorage.getItem('settings');
        const currentSettings = settingsString ? JSON.parse(settingsString) : {};
        currentSettings[ key ] = value;
        return localStorage.setItem('settings', JSON.stringify(currentSettings));
    }

    public loadSettingsValue(key: string) {
        const settingsString = localStorage.getItem('settings');
        if (settingsString) {
            return JSON.parse(settingsString)[ key ];
        }
        return undefined;
    }

    public restart(): void {
        window.location.reload();
    }

    public reboot(): void {
        window.location.reload();
    }

    public exitApp(): void {
        window.location.reload();
    }

    public checkConnection(): string {
        const isOnline = window.navigator.onLine;
        return isOnline ? 'ok' : 'fail';
    }

}
