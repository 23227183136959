import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GeneralService } from '@kuki/global/shared/services/general.service';
import { TranslateService } from '@ngx-translate/core';
import { Tile } from '@kuki/global/shared/types/tile';
import { TileRow } from '@kuki/global/shared/types/general';
import { SOM, SubscriptionObject } from '@kuki/global/shared/others/subscription/subscription-object';
import { ActionButton } from '@kuki/global/shared/types/button';
import { DeviceService } from '@kuki/global/shared/services/device.service';
import { Actions } from '@kuki/global/shared/types/enum';
import { TileService } from '@kuki/global/shared/services/tile.service';
import { AuthService } from '@kuki/global/sections/auth/auth.service';
import { of } from 'rxjs';

@Component({
    selector: 'app-actions-modal',
    templateUrl: './actions-modal.component.html'
})
export class ActionsModalComponent implements OnInit {

    @ViewChild('modalBody', { static: true }) modalBodyEl: ElementRef<HTMLElement>;

    private defaults = {
        message: this.translateService.instant('MODAL.ACTIONS.MESSAGE'),
        closeWithoutButtons: true,
        emptyMessage: this.translateService.instant('MODAL.ACTIONS.EMPTY')
    };
    public data: {
        type: string;
        tile?: Tile;
        tileRow?: TileRow;
        message?: string
        closeWithoutButtons?: boolean;
        emptyMessage?: string;
    };
    private subscription: SubscriptionObject = {};
    public actionButtons: Array<ActionButton>;
    public loaded: boolean = false;

    constructor(
        public ngbActiveModal: NgbActiveModal,
        private authService: AuthService,
        private generalService: GeneralService,
        private deviceService: DeviceService,
        private translateService: TranslateService,
        private tileService: TileService,
        private changeDetectorRef: ChangeDetectorRef) {
    }

    ngOnInit() {
        this.data = { ...this.defaults, ...this.data };
        this.subscription.initButtons = this.initButtons().subscribe(() => {
            this.loaded = true;
            if (this.data.closeWithoutButtons && this.actionButtons.length === 0) {
                this.ngbActiveModal.dismiss();
                return;
            }
            this.changeDetectorRef.detectChanges();
        });
    }

    public onDismissModal() {
        this.ngbActiveModal.dismiss();
    }

    public onCloseModal() {
        this.ngbActiveModal.close();
    }

    private initButtons() {
        this.actionButtons = [];
        switch (this.data.type) {
            case 'teleport-to':
                const devices = this.deviceService.getDevicesForTeleport(this.authService.serial);
                this.actionButtons = devices.map(device => (
                    {
                        action: Actions.TELEPORT_TO,
                        label: device.alias,
                        id: device.id,
                        icon: this.tileService.getDeviceIcon(device)
                    }
                ));
                break;
        }
        return of(undefined);
    }

    public onAction(activeButton: ActionButton) {
        SOM.clearSubscriptions(this.subscription.onButtonAction);
        this.subscription.onButtonAction = this.tileService.doAction(activeButton.action, this.data.tile, activeButton)
            .subscribe(() => {
                this.ngbActiveModal.close(activeButton.action);
            }, () => {
                this.ngbActiveModal.close(activeButton.action);
            });
    }
}
