import { ChangeDetectorRef, Component, ElementRef, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GeneralService } from '@kuki/global/shared/services/general.service';
import { TranslateService } from '@ngx-translate/core';
import { timer } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { SOM, SubscriptionObject } from '@kuki/global/shared/others/subscription/subscription-object';

@Component({
    selector: 'app-confirm-modal',
    templateUrl: './confirm-modal.component.html'
})
export class ConfirmModalComponent implements OnInit, OnDestroy {

    @ViewChild('modalBody', { static: true }) modalBodyEl: ElementRef<HTMLElement>;
    @ViewChild('yes', { static: true }) elYes: ElementRef;
    @ViewChild('no', { static: true }) elNo: ElementRef;

    private defaults = {
        message: this.translateService.instant('MODAL.CONFIRM.DEFAULT_MESSAGE'),
        closeOnAnyBtn: false,
        focusedEl: 'yes',
        timeout: 0
    };

    public data: {
        message: string;
        closeOnAnyBtn: boolean;
        focusedEl: string;
        timeout: number;
    };

    public timeoutTimer: number;
    private subscription: SubscriptionObject = {};

    constructor(
        public ngbActiveModal: NgbActiveModal,
        private generalService: GeneralService,
        private translateService: TranslateService,
        private ngZone: NgZone,
        private changeDetectorRef: ChangeDetectorRef) {
    }

    ngOnInit() {
        this.data = { ...this.defaults, ...this.data };
        this.generalService.focusEl(this.data.focusedEl === 'yes' ? this.elYes : this.elNo);
        if (this.data.timeout) {
            this.ngZone.runOutsideAngular(() => {
                this.subscription.timeoutTimer = timer(0, 1000).pipe(
                    map((seconds) => {
                        return Math.ceil((this.data.timeout / 1000) - seconds);
                    }),
                    tap((remain) => {
                        if (remain === 0) {
                            this.onCloseModal(this.data.focusedEl === 'yes');
                        }
                    })
                ).subscribe((time) => {
                    this.timeoutTimer = time;
                    this.changeDetectorRef.detectChanges();
                });
            });
        }
    }

    onDismissModal() {
        this.ngbActiveModal.dismiss();
    }

    onCloseModal(val) {
        if (this.data.closeOnAnyBtn || val) {
            this.ngbActiveModal.close(val);
        } else {
            this.ngbActiveModal.dismiss();
        }
    }

    ngOnDestroy() {
        SOM.clearSubscriptionsObject(this.subscription);
    }
}
