import { Injectable, NgModule } from '@angular/core';
import { AppSharedModule } from '../app.shared.module';
import { AppComponent } from './app.component';
import { FooterComponent } from '@kuki/web/features/footer/footer.component';
import { AppRoutingModule } from '@kuki/web/app-routing.module';
import { ModalsModule } from '@kuki/web/modals/modals.module';
import { HeaderModule } from '@kuki/web/features/header/header.module';
import { DragulaModule } from 'ng2-dragula';
import { TranslateModule } from '@ngx-translate/core';
import { ContextMenuModule } from '@kuki/web/features/context-menu/context-menu.module';
import { HAMMER_GESTURE_CONFIG, HammerGestureConfig, HammerModule } from '@angular/platform-browser';
import { hal } from '@kuki/platforms/hal';
import { RemoteControlModule } from '@kuki/web/features/remote-control/remote-control.module';
import { HashLocationStrategy, LocationStrategy, PathLocationStrategy } from '@angular/common';

declare var Hammer;
//
// export class MyHammerConfig extends HammerGestureConfig {
//     buildHammer(element: HTMLElement) {
//         return new Hammer(element, {
//             touchAction: 'pan-y',
//             recognizers: [
//                 [ Hammer.Swipe, { direction: Hammer.DIRECTION_ALL } ],
//             ]
//         });
//     }
// }

@Injectable()
export class HammerConfig extends HammerGestureConfig {
    overrides = <any>{
        'pinch': { enable: true },
        'swipe': { direction: [ 'MOBILE.ANDROID', 'MOBILE.IOS' ].indexOf(hal.platform) >= 0 ? Hammer.DIRECTION_ALL : undefined }
    };
}

@NgModule({
    declarations: [
        AppComponent,
        FooterComponent
    ],
    imports: [
        AppSharedModule,
        AppRoutingModule,
        ModalsModule,
        HeaderModule,
        DragulaModule.forRoot(),
        TranslateModule,
        ContextMenuModule,
        HammerModule,
        RemoteControlModule.forRoot()
    ],
    bootstrap: [
        AppComponent
    ],
    providers: [
        {
            // hammer instantion with custom config
            provide: HAMMER_GESTURE_CONFIG,
            useClass: HammerConfig,
        },
        {
            provide: LocationStrategy,
            useClass: hal.platform === 'MOBILE.IOS' ? HashLocationStrategy : PathLocationStrategy
        }
    ]
})
export class AppModule {
}
