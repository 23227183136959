import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '@kuki/global/sections/auth/auth.service';
import { SOM, SubscriptionObject } from '@kuki/global/shared/others/subscription/subscription-object';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html'
})
export class FooterComponent implements OnInit, OnDestroy {

    public customerCare: string;
    private subscription: SubscriptionObject = {};
    actualYear = new Date().getFullYear();

    constructor(private authService: AuthService) {
    }

    ngOnInit() {
        this.subscription.getCustomerCare = this.authService.getCustomerCare().subscribe(customerCare => {
            this.customerCare = customerCare;
        });
    }

    ngOnDestroy() {
        SOM.clearSubscriptionsObject(this.subscription);
    }
}
