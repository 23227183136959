import { Component, HostListener, NgZone, OnInit, ViewContainerRef } from '@angular/core';
import { SOM, SubscriptionObject } from '@kuki/global/shared/others/subscription/subscription-object';
import { NavigationService } from '@kuki/global/shared/services/navigation.service';
import { fromEvent } from 'rxjs';
import { hal } from '@kuki/platforms/hal';
import { StartupService } from '@kuki/global/shared/services/startup.service';
import { NavigatorService } from '@kuki/global/shared/services/navigator.service';
import { CoreService } from '@kuki/global/shared/services/core.service';
import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';
import { ProfileService } from '@kuki/global/shared/services/profile.service';
import { WSService } from '@kuki/global/shared/services/ws.service';
import { AuthService } from '@kuki/global/sections/auth/auth.service';
import { LoggingService } from '@kuki/global/shared/services/logging.service';
import { LogEvents } from '@kuki/global/shared/types/enum';

// import { ContextMenuService } from '@kuki/web/features/context-menu/context-menu.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
    private subscription: SubscriptionObject = {};
    public unsupported: string;

    public _counter: number = 0;

    constructor(
        private startupService: StartupService,
        private navigatorService: NavigatorService,
        private navigationService: NavigationService,
        private coreService: CoreService,
        private profileService: ProfileService,
        private authService: AuthService,
        private angulartics2GoogleAnalytics: Angulartics2GoogleAnalytics,
        private wsService: WSService,
        private loggingService: LoggingService,
        // private contextMenuService: ContextMenuService,
        private viewContainerRef: ViewContainerRef,
        private ngZone: NgZone) {
        angulartics2GoogleAnalytics.startTracking();
    }

    ngOnInit() {
        // init GA
        if (hal.platform === 'WEB') {
            this.detectSupportedBrowsers();
            this.watchHistoryPop();
        } else if (hal.platform === 'MOBILE.ANDROID') {
            this.watchBackButton();
        } else if (hal.platform === 'MOBILE.IOS') {
            this.watchHistoryPop();
        }
        // this.contextMenuService.setViewContainerRef(this.viewContainerRef);
        this.coreService.watchRegister();
        this.subscription.start = this.startupService.start().subscribe(() => {
            this.coreService.initImageLinkFactory();
            this.coreService.initGa();
            this.coreService.initUniversalLinks();
            this.coreService.initAds();
            this.coreService.watchRouter();
            this.coreService.watchHistory();
            this.coreService.watchRecord();
            this.coreService.watchResize();
            this.coreService.watchExit();
        }, (error) => {
            console.log('Can\'t start app');
            console.error(error);
        });
    }

    public isReady() {
        return this.startupService.isReady();
    }

    private detectSupportedBrowsers() {
        if (this.navigatorService.isIE()) {
            this.unsupported = 'ie';
            return;
        }
        if (this.navigatorService.isAndroidPhone()) {
            this.unsupported = 'android';
            return;
        }
        if (this.navigatorService.isIphone()) {
            this.unsupported = 'ios';
            return;
        }
    }

    private watchHistoryPop() {
        this.subscription.onHistoryPop = fromEvent(window, 'popstate').subscribe(() => {
            this.navigationService.navigationOnPop = true;
        });
    }

    private watchBackButton() {
        this.subscription.onBackButton = fromEvent(document, 'backbutton').subscribe(() => {
            this.ngZone.run(() => {
                this.navigationService.navigateBack();
            });
        });
    }

    get counter() {
        return this._counter++;
    }

    @HostListener('window:beforeunload')
    beforeUnloadHander() {
        SOM.clearSubscriptionsObject(this.subscription);
        this.wsService.sendPowerChange(this.authService.serial, false);
        if (this.coreService.isMobilePlatform()) {
            this.profileService.deactivateProfileProcess(false).subscribe({
                error: () => {
                }
            });
        }
        this.loggingService.log(LogEvents.EXIT);
        this.coreService.destroy();
    }
}
