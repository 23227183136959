import { Component, OnInit } from '@angular/core';
import { ContextMenuItem } from '@kuki/global/shared/types/general/context-menu-item';
import { Observable, Subject } from 'rxjs';

@Component({
    selector: 'app-context-menu',
    templateUrl: './context-menu.component.html'
})
export class ContextMenuComponent implements OnInit {

    public items: Array<ContextMenuItem>;
    public _onClick: Subject<ContextMenuItem> = new Subject<ContextMenuItem>();
    public onClick$: Observable<any> = this._onClick.asObservable();

    constructor() {
    }

    ngOnInit(): void {
    }
}
