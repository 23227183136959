import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContextMenuComponent } from './context-menu.component';
import { ContextMenuService } from '@kuki/web/features/context-menu/context-menu.service';


@NgModule({
    declarations: [ ContextMenuComponent ],
    imports: [
        CommonModule
    ],
    providers: [
        ContextMenuService
    ]
})
export class ContextMenuModule {
}
