import { version, versionBuild } from '../../package.json';

export const environment = {
    production: true,
    public: true,
    apiUrl: 'https://as.kuki.cz/api-v2/',
    whitelistedDomains: [ 'as.kuki.cz' ],
    blacklistedRoutes: [ 'https://as.kuki.cz/api-v2/register' ],
    sentry: {
        dsn: 'https://8c757a42dfb04d0c9079e08b4cd75f31@sentry.kuki.cz/27',
        dsnStb: 'http://8c757a42dfb04d0c9079e08b4cd75f31@sentry.kuki.cz/27'
    },
    gaId: 'UA-127720757-1',
    version: version,
    versionBuild: versionBuild
};
