import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfilePickerComponent } from './profile-picker.component';
import { ImageLinkFactoryModule } from '@kuki/global/shared/modules/image-link-factory/image-link-factory.module';
import { DirectivesModule } from '@kuki/global/shared/directives/directives.module';
import { TranslateModule } from '@ngx-translate/core';
import { PipesModule } from '@kuki/global/shared/pipes/pipes.module';
import { ProfileIconModule } from '@kuki/global/features/profile-icon/profile-icon.module';

@NgModule({
    imports: [
        CommonModule,
        ImageLinkFactoryModule,
        DirectivesModule,
        TranslateModule,
        PipesModule,
        ProfileIconModule
    ],
    declarations: [ ProfilePickerComponent ],
    exports: [ ProfilePickerComponent ]
})
export class ProfilePickerModule {
}
