import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '@kuki/global/sections/auth/auth.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SubscriptionObject } from '@kuki/global/shared/others/subscription/subscription-object';
import { GeneralService } from '@kuki/global/shared/services/general.service';
import { ProfileService } from '@kuki/global/shared/services/profile.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-password-modal',
    templateUrl: './password-modal.component.html'
})
export class PasswordModalComponent implements OnInit {

    @ViewChild('input') inputEl: ElementRef<HTMLElement>;
    @ViewChild('modalBody', { static: true }) modalBodyEl: ElementRef<HTMLElement>;

    public data: {
        placeholder?: string;
        type?: 'device' | 'profile';
        profileId?: number;
    } = {};

    public passwordForm: FormGroup;
    private subscription: SubscriptionObject = {};

    constructor(
        private authService: AuthService,
        private formBuilder: FormBuilder,
        private profileService: ProfileService,
        private translateService: TranslateService,
        private generalService: GeneralService,
        public ngbActiveModal: NgbActiveModal) {
    }

    ngOnInit() {
        this.data = this.data || {};
        this.data.placeholder = this.data.placeholder || this.translateService.instant('MODAL.PASSWORD.PLACEHOLDER');
        this.passwordForm = this.formBuilder.group({
            password: [ '', Validators.required ]
        });
    }

    onSubmit() {
        if (!this.passwordForm.valid) {
            return;
        }
        if (this.subscription.checkPassword) {
            return;
        }
        const password = this.passwordForm.value.password;
        const checkApi$ = this.data && this.data.type === 'profile' ?
            this.profileService.checkPassword(this.data.profileId, password) :
            this.authService.checkPassword(password);
        this.subscription.checkPassword = checkApi$.subscribe((res: any) => {
            this.subscription.checkPassword = null;
            if (res && res.state === 'OK') {
                this.ngbActiveModal.close(password);
            }
        }, () => {
            this.subscription.checkPassword = null;
            this.passwordForm.setValue({ 'password': '' });
            this.generalService.focusEl(this.inputEl);
        });
    }

    onDismissModal() {
        this.ngbActiveModal.dismiss();
    }
}
