import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '@kuki/global/sections/auth/auth.service';
import { NpvrStats } from '@kuki/global/shared/types/state/npvr-stats';
import { NavigationService } from '@kuki/global/shared/services/navigation.service';
import { ModalsInterface } from '@kuki/global/modals/modals.interface';
import { NpvrService } from '@kuki/global/shared/modules/media/npvr.service';
import { environment } from '@kuki/environments/environment';
import { SOM, SubscriptionObject } from '@kuki/global/shared/others/subscription/subscription-object';
import { Actions } from '@kuki/global/shared/types/enum';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit, OnDestroy {

    public npvrStats: NpvrStats;
    public npvrFullness: number;

    public publicVersion = environment.public;
    private subscription: SubscriptionObject = {};

    constructor(
        private authService: AuthService,
        @Inject('ModalsService') private modalsService: ModalsInterface,
        private npvrService: NpvrService,
        private navigationService: NavigationService) {
    }

    ngOnInit() {
        this.npvrStats = this.npvrService.getNpvrStats();
        this.npvrFullness = this.npvrService.getNpvrFullness();
        this.subscription.npvrStatsUpdated$ = this.npvrService.npvrStatsUpdated$.subscribe(() => {
            this.npvrStats = this.npvrService.getNpvrStats();
            this.npvrFullness = this.npvrService.getNpvrFullness();
        });
    }

    public onClickNpvr() {
        this.navigationService.navigateOnAction(Actions.SHOW_NPVR_BOARD);
    }

    public onBack() {
        this.navigationService.navigateBack();
    }

    public getSearchFormClass() {
        return {
            'search-form-small': this.npvrFullness > 0
        };
    }

    ngOnDestroy() {
        SOM.clearSubscriptionsObject(this.subscription);
    }
}
