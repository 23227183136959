import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GeneralService } from '@kuki/global/shared/services/general.service';
import { TranslateService } from '@ngx-translate/core';
import { SOM, SubscriptionObject } from '@kuki/global/shared/others/subscription/subscription-object';
import { ActionButton } from '@kuki/global/shared/types/button';
import { DeviceService } from '@kuki/global/shared/services/device.service';
import { AuthService } from '@kuki/global/sections/auth/auth.service';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-actions-modal-v2',
    templateUrl: './actions-modal-v2.component.html'
})
export class ActionsModalV2Component implements OnInit {

    @ViewChild('modalBody', { static: true }) modalBodyEl: ElementRef<HTMLElement>;

    private defaults = {
        message: this.translateService.instant('MODAL.ACTIONS.MESSAGE'),
        emptyMessage: this.translateService.instant('MODAL.ACTIONS.EMPTY')
    };
    public data: {
        buttons: Array<ActionButton>;
        onAction: (actionButon: ActionButton, ngbActiveModal: NgbActiveModal) => Observable<any>;
        message?: string
        emptyMessage?: string;
    };
    private subscription: SubscriptionObject = {};

    constructor(
        public ngbActiveModal: NgbActiveModal,
        private authService: AuthService,
        private generalService: GeneralService,
        private deviceService: DeviceService,
        private translateService: TranslateService) {
    }

    ngOnInit() {
        this.data = { ...this.defaults, ...this.data };
    }

    public onDismissModal() {
        this.ngbActiveModal.dismiss();
    }

    public onCloseModal() {
        this.ngbActiveModal.close();
    }

    public onAction(actionButton: ActionButton) {
        if (this.data.onAction) {
            SOM.clearSubscriptions(this.subscription.onAction);
            this.subscription.onAction = this.data.onAction(actionButton, this.ngbActiveModal).subscribe(() => {
                this.ngbActiveModal.close(actionButton);
            });
        } else {
            this.ngbActiveModal.close(actionButton);
        }
    }
}
