import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@kuki/environments/environment';
import { MediaTypes } from '@kuki/global/shared/types/enum';
import { ChannelService } from '@kuki/global/shared/services/channel.service';
import { MediaTile } from '@kuki/global/shared/types/tile';
import { Channel, Log } from '@kuki/global/shared/types/general';
import { NavigationService } from '@kuki/global/shared/services/navigation.service';
import { DeviceService } from '@kuki/global/shared/services/device.service';
import { switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable()
export class RemoteControlService {

    constructor(
        private channelService: ChannelService,
        private navigationService: NavigationService,
        private deviceService: DeviceService,
        private httpClient: HttpClient) {
    }

    public getDeviceState(deviceId: number) {
        return this.httpClient.get<Log>(`${ environment.apiUrl }remote/state/${ deviceId }`);
    }

    public sendAction(deviceId: number, action: string, data: any = {}) {
        return this.httpClient.post(`${ environment.apiUrl }remote/${ deviceId }`, {
            action: action,
            ...data
        });
    }

    public seek(deviceId: number, position: number) {
        return this.sendAction(deviceId, 'seek', { position: position });
    }

    public setAudioTrack(deviceId: number, language: string, layout: string) {
        return this.sendAction(deviceId, 'setAudio', { lang: language, layout: layout });
    }

    public setSubtitleTrack(deviceId: number, language: string) {
        return this.sendAction(deviceId, 'setSubtitles', { lang: language });
    }

    public powerOnDevice(deviceId) {
        return this.sendAction(deviceId, 'poweron');
    }

    public powerOffDevice(deviceId) {
        return this.sendAction(deviceId, 'poweroff');
    }

    public getSignIdent(tile: MediaTile, channel: Channel): string {
        switch (tile.mediaType) {
            case MediaTypes.EPG_ENTITY:
            case MediaTypes.EPISODE:
                return channel.ident;
            case MediaTypes.NPVR:
            case MediaTypes.VOD:
                return tile.ident;
        }
    }

    public startControl(deviceId: number) {
        const device = this.deviceService.getDevice(deviceId);
        const obs$ = !device.online && device.cap.wakeOnRemote ? this.powerOnDevice(deviceId) : of(undefined);
        return obs$.pipe(switchMap(() => this.navigationService.navigateRemoteControl(deviceId)));
    }
}
