import { WebKeyMapModel } from '@kuki/global/shared/types/controller/keymap';

export class WebKeyMap implements WebKeyMapModel {
    OK = 13;
    GRP_BACK = 8;
    SPACE = 32;
    UP = 38;
    DOWN = 40;
    LEFT = 37;
    RIGHT = 39;
    SLASH = 191;

    N_0 = 48;
    N_1 = 49;
    N_2 = 50;
    N_3 = 51;
    N_4 = 52;
    N_5 = 53;
    N_6 = 54;
    N_7 = 55;
    N_8 = 56;
    N_9 = 57;
}
