<form class="form search-form">
    <div class="input-group">
        <div class="input-group-icon input-group-icon-left">
            <span class="icon icon-search"></span>
        </div>
        <input type="text"
               class="form-control"
               name="query"
               #searchInput
               [placeholder]="'GENERAL.SEARCH_FORM.SEARCHING'| translate"
               [ngModel]="query"
               (click)="onShowSearch()"
               (focus)="onShowSearch()"
               (ngModelChange)="onSearch($event)">
        <div class="input-group-icon input-group-icon-right" (click)="onClickSearchIcon()">
            <span class="icon" [ngClass]="getSearchOpenIcon()"></span>
        </div>
    </div>
</form>
